import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function AlertMessage(props) {
  if (props.alertInfo.alertType === "success") {
    toast.success(
      <h5>
        <strong>{props.name}</strong> {props.alertInfo.message}
      </h5>
    );
  }
  return (
    <>
      <ToastContainer />
    </>
  );
}
