/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink, Link } from "react-router-dom";
// import SVG from "react-inlinesvg";
import { checkIsActive } from "../../../../_helpers";
import Utils from "../../../../../components/shared/Utils";

export function HeaderMenu({ layoutProps }) {
  const utils = new Utils();
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/*begin::Header Nav*/}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            "/dashboard"
          )}`}
        >
          <NavLink className="menu-link" to="/admin-dashboard">
            <span className="menu-text">Dashboard</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
        {/*            
            <li
                data-menu-toggle={layoutProps.menuDesktopToggle}
                aria-haspopup="true"
                className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/google-material')}`}>
                <NavLink className="menu-link menu-toggle" to="/google-material">
                    <span className="menu-text">Home</span>
                    <i className="menu-arrow"></i>
                </NavLink>
            </li> */}

        {/*end::1 Level*/}

        {/*Mega submenu*/}
        {/*begin::1 Level*/}

        {/*Classic submenu*/}
        {/*begin::1 Level*/}
        <li
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
            "/custom"
          )}`}
        >
          <NavLink className="menu-link menu-toggle" to="/custom">
            <span className="menu-text">Actions</span>
            <i className="menu-arrow"></i>
          </NavLink>
          <div className="menu-submenu menu-submenu-classic menu-submenu-left">
            <ul className="menu-subnav">
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/e-commerce"
                )}`}
                data-menu-toggle="hover"
                aria-haspopup="true"
              >
                <Link
                  className="menu-link"
                  to={{ pathname: "/reports", state: { value: "reports" } }}
                >
                  <span className="menu-text">Reports</span>
                </Link>
              </li>
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              <li className={`menu-item ${getMenuItemActive("/builder")}`}>
                <Link
                  className="menu-link"
                  to={{
                    pathname: "/subscribers",
                    state: { value: "subscriber" },
                  }}
                >
                  <span className="menu-text">Subscribers</span>
                </Link>
              </li>
              {/*end::3 Level*/}

              {/*begin::2 Level*/}
              <li className={`menu-item ${getMenuItemActive("/builder")}`}>
                <Link
                  className="menu-link"
                  to={{
                    pathname: "/subscriptions",
                    state: { value: "subscription" },
                  }}
                >
                  <span className="menu-text">Subscriptions</span>
                </Link>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/error"
                )}`}
                data-menu-toggle="hover"
                aria-haspopup="true"
              >
                <Link
                  className="menu-link"
                  to={{ pathname: "/carriers", state: { value: "carriers" } }}
                >
                  <span className="menu-text">Carriers</span>
                </Link>
              </li>

              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/error"
                )}`}
                data-menu-toggle="hover"
                aria-haspopup="true"
              >
                <Link
                  className="menu-link"
                  to={{
                    pathname: "/user-management",
                    state: { value: "user-management" },
                  }}
                >
                  <span className="menu-text">User Management</span>
                </Link>
              </li>

              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        <li
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
            "/react-bootstrap"
          )}`}
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
        >
          <NavLink
            className="menu-link"
            to={{
              pathname: "/settings",
              state: { value: "settings" },
            }}
          >
            <span className="menu-text">Settings</span>
          </NavLink>
        </li>

        <li
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
          onClick={() => utils.logOut()}
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
            "/google-material"
          )}`}
        >
          <NavLink className="menu-link menu-toggle" to="/admin-logout">
            <span className="menu-text">Log Out</span>
            <i className="menu-arrow"></i>
          </NavLink>
        </li>

        {/*end::1 Level*/}
      </ul>
      {/*end::Header Nav*/}
    </div>
  );
}
