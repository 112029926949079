export const createAccountConstants = {
    CREATE_ACCOUNT_STATE: "CREATE_ACCOUNT_STATE",
    GET_SUBSCRIPTION_PLANS_REQUEST: "GET_SUBSCRIPTION_PLANS_REQUEST",
    GET_SUBSCRIPTION_PLANS_SUCCESS: "GET_SUBSCRIPTION_PLANS_SUCCESS",
    GET_SUBSCRIPTION_PLANS_FAILURE: "GET_SUBSCRIPTION_PLANS_FAILURE",
    CREATE_USER_REQUEST: "CREATE_USER_REQUEST",
    CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
    CREATE_USER_FAILURE: "CREATE_USER_FAILURE",

};
