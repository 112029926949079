import React, { useMemo } from "react";
import { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { Modal } from "react-bootstrap";
import { Button } from "primereact/components/button/Button";
import NinjaProgressSpinner from "../../components/shared/NinjaProgressSpinner";
import Utils from "../../components/shared/Utils";
import { toAbsoluteUrl } from "../_helpers";
import $ from "jquery";
import PaginationGen from "./PaginationGen";
import { Link } from "react-router-dom";
// import SubscriberActivity from "./SubscriberActivity";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { AlertMessage } from "./AlertMessage";
import ReferUsersInfo from "./ReferUserInfo";

export default function UserManagement() {
  const utils = new Utils(); // To create instance
  const [state, setstate] = useState({}); // State of this component
  const [qury, setqury] = useState("");
  // for confirmation modal
  const [show, setShow] = useState(false);
  const [alertShow, setAlertShow] = useState(false);
  const [alertInfo, setAlertInfo] = useState({
    message: "",
    alertType: "",
  });
  const [delModelShow, setDelModelShow] = useState(false);
  const [referUsersInfo, setReferUsersInfo] = useState({});
  const [referUsersInfoState, setReferUsersInfoState] = useState({});
  const [referUsersModel, setReferUsersModel] = useState(false);
  const [modalData, setModalData] = useState({});
  const [loader, setLoader] = useState(true);

  const handleClose = () => {
    setShow(false);
  };
  function handleShow(
    userId,
    firstName,
    lastName,
    email,
    phone,
    city,
    country,
    createdAt,
    lastLoginAt,
    state,
    streetAddress,
    updatedAt,
    userRole,
    zip,
    username
  ) {
    setShow(true);
    setModalData({
      userId,
      firstName,
      lastName,
      email,
      phone,
      city,
      country,
      createdAt,
      lastLoginAt,
      state,
      streetAddress,
      updatedAt,
      userRole,
      zip,
      username,
    });
  }
  //

  const handleEdit = (userId, fName, lName, email, userName) => {
    utils.createCookie("UserManage_Id", userId);
    utils.createCookie("UserManage_fn", fName);
    utils.createCookie("UserManage_ln", lName);
    utils.createCookie("UserManage_email", email);
    utils.createCookie("UserManage_Uname", userName);
  };

  // Api define getAllUsersWithOutAdmin
  function getAllUsersWithOutAdmin(page, priceId) {
    // sinner call
    setLoader(true);
    $("#spinnerWrap").css("display", "flex");
    $("#ID_NINJA_SPINNER_IN_PROGRESS").show();
    let url = utils.getServicesUrl() + "/getAllUsersWithOutAdmin";
    let token = utils.readCookie("token");

    var myHeaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    var data = JSON.stringify({
      userName: "",
      fromDate: "",
      toDate: "",
      pageNumber: page,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: data,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setstate(result);
        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        $("#spinnerWrap").css("display", "none");
        setLoader(false);
        if (alertShow) {
          setAlertInfo({
            message: "deleted successfully",
            alertType: "success",
          });
        }
      })
      .catch((error) => {
        // console.log('error', error)
        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        $("#spinnerWrap").css("display", "none");
        setLoader(false);
        // alert(error);
      });
  }
  // End

  const unixDate = (unix) => {
    var a = new Date(unix);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var time = month + " " + date + "," + " " + year;
    return time;
  };

  // Filter Data
  const filterData = (s) => {
    let q = qury;
    try {
      if (
        s.firstName.toUpperCase().includes(q.toUpperCase()) ||
        s.firstName.toLowerCase().includes(q.toLowerCase()) ||
        s.lastName.toUpperCase().includes(q.toUpperCase()) ||
        s.lastName.toLowerCase().includes(q.toLowerCase()) ||
        s.email.toUpperCase().includes(q.toUpperCase()) ||
        s.email.toLowerCase().includes(q.toLowerCase()) ||
        s.userId.toUpperCase().includes(q.toUpperCase()) ||
        s.userId.toLowerCase().includes(q.toLowerCase()) ||
        s.username.toUpperCase().includes(q.toUpperCase()) ||
        s.username.toLowerCase().includes(q.toLowerCase()) ||
        s.phone.includes(q) ||
        s.subStatus.toUpperCase().includes(q.toUpperCase()) ||
        s.subStatus.toLowerCase().includes(q.toLowerCase())
      ) {
        return s;
      }
    } catch (err) {
      // console.log("err", err.message)
    }
  };
  // End

  const redirectUrl = () => {
    window.location.href = "/admin-dashboard";
  };

  // getReferUsersInfo
  function getReferUsersInfo(page, userId) {
    // sinner call
    setLoader(true);
    $("#spinnerWrap").css("display", "flex");
    $("#ID_NINJA_SPINNER_IN_PROGRESS").show();
    let url = utils.getServicesUrl() + "/getReferUsersInfo";
    let token = utils.readCookie("token");

    var myHeaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    var raw = JSON.stringify({
      fromDate: "",
      toDate: "",
      pageNo: page,
      userId: userId,
      userName: "",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    var statusCode;
    fetch(url, requestOptions)
      .then((response) => {
        statusCode = response.status;
        return response.json();
      })
      .then((result) => {
        if (statusCode === 403) {
          setReferUsersInfoState({
            ...setReferUsersInfoState,
            msg403: "You don't have access to this feature.",
          });
        } else {
          let newContent = [];
          result.content.map((obj) => {
            obj.createdAt = unixDate(obj.createdAt);
            if (obj.register) {
              obj.register = "Registered";
            } else {
              obj.register = "Not Registered";
            }
            if (obj.regEmail == null || obj.regEmail == "") {
              obj.regEmail = "Null";
            }
            if (
              obj.regFirstName == null ||
              (obj.regFirstName == "" && obj.regLastName == null) ||
              obj.regLastName == ""
            ) {
              obj.regFirstName = "Null";
              obj.regLastName = "Null";
            }
            if (obj.regUsername == null || obj.regUsername == "") {
              obj.regUsername = "Null";
            }
            if (obj.userId == null || obj.userId == "") {
              obj.userId = "Null";
            }
            newContent.push(obj);
          });
          result.content = newContent;
          setReferUsersInfoState(result);
        }
        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        $("#spinnerWrap").css("display", "none");
        setLoader(false);
        setReferUsersModel(true);
      })
      .catch((error) => {
        // console.log('error', error)
        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        $("#spinnerWrap").css("display", "none");
        setLoader(false);
        setReferUsersModel(true);
      });
  }
  // End

  // softDeleteUseyById
  function softDeleteUserById() {
    setDelModelShow(false);
    // sinner call
    setLoader(true);
    $("#spinnerWrap").css("display", "flex");
    $("#ID_NINJA_SPINNER_IN_PROGRESS").show();
    let url = utils.getServicesUrl() + "/softDeleteUseyById";
    let token = utils.readCookie("token");

    var myHeaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    var raw = JSON.stringify({
      user_id: referUsersInfo.userId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    var statusCode;
    fetch(url, requestOptions)
      .then((response) => {
        statusCode = response.status;
        return response.json();
      })
      .then((result) => {
        if (statusCode === 403) {
          $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
          $("#spinnerWrap").css("display", "none");
          setLoader(false);
          setReferUsersInfoState({
            ...setReferUsersInfoState,
            msg403: "You don't have access to this feature.",
          });
        } else {
          getAllUsersWithOutAdmin(0, "");
        }
      });
  }
  // End

  useEffect(() => {
    // Api CALL getAllUsersWithOutAdmin
    getAllUsersWithOutAdmin(0, "");
    // End
    window.addEventListener("load", redirectUrl, false);
  }, []);
  return (
    <>
      {/* Alert Message */}
      <AlertMessage
        alertInfo={alertInfo}
        setAlertInfo={setAlertInfo}
        name={referUsersInfo.userName && referUsersInfo.userName}
      />
      {/* End */}
      {/* Search Box */}
      <div className="d-flex justify-content-end">
        <form
          style={{ marginTop: "66px" }}
          className="navbar-form navbar-right"
          role="search"
        >
          <div className="input-group align-items-baseline">
            <input
              type="text"
              id="searchBox"
              onChange={(e) => setqury(e.target.value)}
              className="form-control"
              placeholder="Search"
            />
            <i
              style={{ marginLeft: "-29px", zIndex: "1" }}
              className="flaticon-search-magnifier-interface-symbol"
            ></i>
          </div>
        </form>
      </div>
      {/* End */}
      <h1 style={{ marginBottom: "30px" }}>User Management</h1>
      <div id="spinnerWrap">
        <NinjaProgressSpinner maxWidth="100px" maxHeight="100px" />
      </div>
      <div className={`table-responsive ${loader ? "isLoaderGen" : ""}`}>
        <table
          className="table table-head-custom table-vertical-center"
          id="kt_advance_table_widget_1"
        >
          <thead>
            <tr className="text-left">
              <th className="pr-0" style={{ minWidth: "50px" }}>
                Names
              </th>
              <th style={{ minWidth: "50px" }}>Email</th>
              <th style={{ minWidth: "50px" }}>Phone No</th>
              <th style={{ minWidth: "30px" }}>Date of Subscription</th>
              <th style={{ minWidth: "100px" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {state.content
              ? state.content.filter(filterData).map((val, i) => (
                  <tr key={i}>
                    <td>
                      <OverlayTrigger
                        overlay={
                          <Tooltip
                            style={{
                              background: "#C0C0C0",
                              padding: "5px 5px",
                              borderRadius: "5px",
                            }}
                            id="tooltip"
                          >
                            View detail
                          </Tooltip>
                        }
                      >
                        <span
                          className="btn text-primary font-weight-bolder font-size-lg"
                          id="ID_UPDATE_SUBSCRIPTION"
                          onClick={() => {
                            handleShow(
                              val.userId,
                              val.firstName,
                              val.lastName,
                              val.email,
                              val.phone,
                              val.city,
                              val.country,
                              val.createdAt,
                              val.lastLoginAt,
                              val.state,
                              val.streetAddress,
                              val.updatedAt,
                              val.userRole,
                              val.zip,
                              val.username
                            );
                          }}
                        >
                          {val.firstName} {val.lastName}
                        </span>
                      </OverlayTrigger>
                    </td>
                    <td>
                      <span className="font-weight-500">{val.email}</span>
                    </td>
                    <td>
                      <span className=" font-weight-500">{val.phone}</span>
                    </td>
                    <td>
                      <span className=" font-weight-500">
                        {unixDate(val.createdAt)}
                      </span>
                    </td>
                    <td className="pr-0">
                      <OverlayTrigger
                        overlay={
                          <Tooltip
                            style={{
                              background: "#C0C0C0",
                              padding: "5px 5px",
                              borderRadius: "5px",
                            }}
                            id="tooltip"
                          >
                            update user
                          </Tooltip>
                        }
                      >
                        <span
                          className="btn btn-icon btn-light btn-sm my-3 mx-3"
                          id="ID_UPDATE_SUBSCRIPTION"
                          onClick={() =>
                            handleEdit(
                              val.userId,
                              val.firstName,
                              val.lastName,
                              val.email,
                              val.username
                            )
                          }
                        >
                          <Link
                            className="menu-link"
                            to={{
                              pathname: "/user-edit",
                              state: { value: "user-edit" },
                            }}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <i className="fas fa-user-edit text-primary"></i>
                            </span>
                          </Link>
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip
                            style={{
                              background: "#C0C0C0",
                              padding: "5px 5px",
                              borderRadius: "5px",
                            }}
                            id="tooltip"
                          >
                            Refer users info
                          </Tooltip>
                        }
                      >
                        <span
                          className="btn btn-icon btn-light btn-sm"
                          onClick={() => {
                            setReferUsersInfo({
                              userId: val.userId,
                              userName: val.username,
                              email: val.email,
                            });
                            getReferUsersInfo("0", val.userId);
                          }}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            <i className="fas fa-info text-primary"></i>
                          </span>
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip
                            style={{
                              background: "#C0C0C0",
                              padding: "5px 5px",
                              borderRadius: "5px",
                            }}
                            id="tooltip"
                          >
                            Delete User
                          </Tooltip>
                        }
                      >
                        <span
                          className="btn btn-icon btn-light btn-sm ml-3"
                          onClick={() => {
                            setReferUsersInfo({
                              userId: val.userId,
                              userName: val.username,
                              email: val.email,
                            });
                            setDelModelShow(true);
                            setAlertShow(true);
                          }}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/General/Trash.svg"
                              )}
                            ></SVG>
                          </span>
                        </span>
                      </OverlayTrigger>
                    </td>
                  </tr>
                ))
              : ""}
          </tbody>
        </table>
      </div>
      {state.pageable && (
        <PaginationGen
          prceId=""
          page={state.pageable.pageNumber}
          totalPage={state.totalPages}
          getUsersWithSubscribtionInfo={getAllUsersWithOutAdmin}
        />
      )}
      {/* View Detail */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Subscriber Details </Modal.Title>
          <span id="closeIcon" onClick={handleClose}>
            ✕
          </span>
        </Modal.Header>
        <Modal.Body>
          <table>
            <thead></thead>
            <tbody>
              <tr>
                <th scope="row">User id:</th>
                <td>{modalData.userId}</td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">User name:</th>
                <td>{modalData.username}</td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">Subscriber name:</th>
                <td>{modalData.firstName + " " + modalData.lastName}</td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">Email:</th>
                <td>{modalData.email}</td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">Phone no:</th>
                <td>{modalData.phone}</td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">User role:</th>
                <td>{modalData.userRole}</td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">Street address:</th>
                <td>{modalData.streetAddress}</td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">Zip code:</th>
                <td>{modalData.zip}</td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">City:</th>
                <td>{modalData.city}</td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">State:</th>
                <td>{modalData.state}</td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">Country:</th>
                <td>{modalData.country}</td>
                <td></td>
              </tr>
              <tr>
                <th scope="row"> Date of subscription:</th>
                <td>{unixDate(modalData.createdAt)}</td>
                <td></td>
              </tr>

              <tr>
                <th scope="row"> Updated at:</th>
                <td>{unixDate(modalData.updatedAt)}</td>
                <td></td>
              </tr>

              <tr>
                <th scope="row"> Last login at:</th>
                <td>{unixDate(modalData.lastLoginAt)}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
      {/* End */}
      {/* For Delete user */}
      <Modal
        show={delModelShow}
        onHide={() => setDelModelShow(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Delete user detail</Modal.Title>
          <span id="closeIcon" onClick={() => setDelModelShow(false)}>
            ✕
          </span>
        </Modal.Header>
        <Modal.Body style={{ marginBottom: "0px" }}>
          <table>
            <tr>
              <th>User Id: </th>
              <td> </td>
              <td>{referUsersInfo.userId}</td>
            </tr>
            <tr>
              <th>User Name: </th>
              <td> </td>
              <td>{referUsersInfo.userName}</td>
            </tr>
            <tr>
              <th>User Email: </th>
              <td> </td>
              <td>{referUsersInfo.email}</td>
            </tr>
          </table>
        </Modal.Body>
        <span style={{ marginLeft: "23px" }}>
          Are you sure! you want to delete the "{referUsersInfo.userName}" ?
        </span>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="commonButtonClass"
            onClick={() => setDelModelShow(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            style={{ background: "rgb(226 20 9)", fontWeight: "600" }}
            onClick={softDeleteUserById}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* End */}
      {/* ReferUsersInfo Model */}
      {referUsersModel && (
        <ReferUsersInfo
          referUsersModel={referUsersModel}
          setReferUsersModel={setReferUsersModel}
          referUsersInfoState={referUsersInfoState}
          getReferUsersInfo={getReferUsersInfo}
          referUsersInfo={referUsersInfo}
        />
      )}
      {/* End */}
    </>
  );
}
