/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import $ from "jquery";
// import { Nav, Tab } from 'react-bootstrap'
// import SVG from 'react-inlinesvg'
// import { toAbsoluteUrl } from '../../../_helpers'
import Utils from "../../../../components/shared/Utils";
import { useEffect } from "react";
import NinjaProgressSpinner from "../../../../components/shared/NinjaProgressSpinner";
import PaginationGen from "../../PaginationGen";

export function AdvanceTablesWidget2({ className }) {
  const utils = new Utils(); // To create instance

  const [state, setstate] = useState({}); // State of this component
  const [loader, setLoader] = useState(false);
  // console.log('state', state)

  // Api define getUsersWithSubscribtionInfo
  function getUsersWithSubscribtionInfo(page, priceId) {
    // sinner call
    $("#spinnerWrap").css("display", "flex");
    $("#ID_NINJA_SPINNER_IN_PROGRESS").show();
    setLoader(true);
    let url = utils.getServicesUrl() + "/getUsersWithSubscribtionInfo";
    let token = utils.readCookie("token");

    var myHeaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    var data = JSON.stringify({
      userName: "",
      status: "",
      planId: "",
      priceId: priceId,
      fromDate: "",
      toDate: "",
      pageNumber: page,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: data,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setstate(result);
        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        setLoader(false);
        $("#spinnerWrap").css("display", "none");
      })
      .catch((error) => {
        // console.log('error', error)
        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        setLoader(false);
        $("#spinnerWrap").css("display", "none");
        // alert(error);
      });
  }
  // End

  const unixDate = (unix) => {
    var a = new Date(unix);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var time = month + " " + date + "," + " " + year;
    return time;
  };
  // console.log('unixDate', unixDate())

  // capitalizeFirstLetter
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  // End

  useEffect(() => {
    // Api CALL getUsersWithSubscribtionInfo
    getUsersWithSubscribtionInfo(0, "");
    // End
  }, []);

  return (
    <>
      <div id="spinnerWrap">
        <NinjaProgressSpinner maxWidth="100px" maxHeight="100px" />
      </div>
      <div
        className={`card card-custom ${className} ${
          loader ? "isLoaderGen" : ""
        }`}
      >
        {/* Head */}
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Latest Subscriptions
            </span>
          </h3>
        </div>
        {/* Body */}
        <div className="card-body pt-3 pb-0">
          <div className="table-responsive">
            <table
              className="table table-head-custom  table-vertical-center"
              id="kt_advance_table_widget_1"
            >
              <thead>
                <tr>
                  <th>Names</th>
                  <th>Email</th>
                  <th>Phone No</th>
                  <th>Subscription PLan</th>
                  <th>Subscription status</th>
                  <th>Date of Subscription</th>
                </tr>
              </thead>
              <tbody>
                {state.content &&
                  state.content.map((val, i) => (
                    <tr key={i}>
                      <td>
                        <div className=" mb-1">
                          {val.firstName} {val.lastName}
                        </div>
                      </td>
                      <td>
                        <span className=" mb-1">
                          {val.email}
                        </span>
                      </td>
                      <td>
                        <span className=" ">
                          {val.phone}
                        </span>
                      </td>
                      <td style={{ minWidth: "50px" }}>
                        <span className=" ">
                          {val.planName == null ? "" : val.planName}
                        </span>
                      </td>
                      <td style={{ minWidth: "50px" }}>
                        <span
                          style={{
                            fontWeight: "600",
                            padding: "2px 4px 4px 4px",
                            borderRadius: "0px",
                          }}
                          className={` badge  ${
                            val.subStatus.toLowerCase() === "active"
                              ? "badge-success"
                              : ""
                          } ${
                            val.subStatus.toLowerCase() === "suspend"
                              ? "badge-warning"
                              : ""
                          } ${
                            val.subStatus.toLowerCase() === "cancel"
                              ? "badge-danger"
                              : ""
                          }  `}
                        >
                          {val.subStatus &&
                            capitalizeFirstLetter(val.subStatus)}
                        </span>
                      </td>
                      <td>
                        <span className=" ">
                          {unixDate(val.createdAt)}
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {state.pageable && (
            <PaginationGen
              prceId=""
              page={state.pageable.pageNumber}
              totalPage={state.totalPages}
              getUsersWithSubscribtionInfo={getUsersWithSubscribtionInfo}
            />
          )}
        </div>
      </div>
    </>
  );
}
