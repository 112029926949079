import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import SVG from "react-inlinesvg";
import Utils from "../../../../components/shared/Utils";
import { toAbsoluteUrl } from "../../../_helpers";
// import {useHtmlClassService} from "../../../layout";
const utils = new Utils();

export function MixedWidget1({ className }) {
  const [activeStatus, setActiveStatus] = useState("");
  const [suspendStatus, setSuspendStatus] = useState("");
  const [cancelStatus, setCancelStatus] = useState("");
  // console.log("getSubscriptionsStatusWise", state);

  function getSubscriptionsStatusWise() {
    let url = utils.getServicesUrl() + "/getSubscriptionsStatusWise";
    let token = utils.readCookie("token");

    var myHeaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        result.map((v) => {
          if (v.subStatus.toLowerCase() === "active") {
            setActiveStatus(v.number);
          } else if (v.subStatus.toLowerCase() === "suspend") {
            setSuspendStatus(v.number);
          } else if (v.subStatus.toLowerCase() === "cancel"){
            setCancelStatus(v.number);
          }
        });
      })
      .catch((error) => {
        // console.log('error', error)
      });
  }

  useEffect(() => {
    getSubscriptionsStatusWise();
  }, []);
  return (
    <div className={`card card-custom bg-gray-100 ${className}`}>
      {/* Header */}
      <div className="card-header border-0 bg-danger py-5">
        <h3 className="card-title font-weight-bolder text-white">Dashboard</h3>
        <div className="card-toolbar"></div>
      </div>
      {/* Body */}
      <div className="card-body p-0 position-relative overflow-hidden">
        {/* Chart */}
        <div
          id="kt_mixed_widget_1_chart"
          className="card-rounded-bottom bg-danger"
          style={{ height: "200px" }}
        ></div>

        {/* Stat */}
        <div className="card-spacer mt-n25">
          <div className="row m-0">
            <div className="col bg-light-success px-8 py-8 rounded-xl mr-7 mb-7">
              <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Media/Equalizer.svg")}
                ></SVG>
              </span>
              <span class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block">
                {activeStatus ? activeStatus : 0}
              </span>
              <a href="#" className="font-weight-bold text-dark-75 font-size-sm">
                Active
              </a>
            </div>
            <div className="col bg-light-warning px-8 py-8 rounded-xl mr-7 mb-7">
              <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Media/Equalizer.svg")}
                ></SVG>
              </span>
              <span class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block">
                {suspendStatus ? suspendStatus : 0}
              </span>
              <a
                href="#"
                className="font-weight-bold text-dark-75 font-size-sm mt-2"
              >
                Suspended
              </a>
            </div>

            <div className="col bg-light-danger px-8 py-8 rounded-xl mb-7">
              <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Media/Equalizer.svg")}
                ></SVG>
              </span>
              <span class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block">
                {cancelStatus ? cancelStatus : 0}
              </span>
              <a
                href="#"
                className="font-weight-bold text-dark-75 font-size-sm mt-2"
              >
                Canceled
              </a>
            </div>
          </div>

          {/* <div className="row m-0">
            <div className="col bg-light-secondary px-6 py-8 rounded-xl mr-7 mb-7">
              <span className="svg-icon svg-icon-3x svg-icon-secondary d-block my-2">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Media/Equalizer.svg")}
                ></SVG>
              </span>
              <a
                href="#"
                className="text-secondary font-weight-bold font-size-h6"
              >
                New and registered Agents
              </a>
            </div>
            <div className="col bg-light-info px-6 py-8 rounded-xl mb-7">
              <span className="svg-icon svg-icon-3x svg-icon-info d-block my-2">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Urgent-mail.svg"
                  )}
                ></SVG>
              </span>
              <a
                href="#"
                className="text-info font-weight-bold font-size-h6 mt-2"
              >
                Instant Binds
              </a>
            </div>
          </div> */}

          {/* <div className="row m-0">
            <div className="col bg-light-primary px-6 py-8 rounded-xl mr-7 mb-7">
              <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Add-user.svg"
                  )}
                ></SVG>
              </span>
              <a
                href="#"
                className="text-primary font-weight-bold font-size-h6 mt-2"
              >
                Paying Agents
              </a>
            </div>
            <div className="col bg-light-dark px-6 py-8 rounded-xl mb-7">
              <span className="svg-icon svg-icon-3x svg-icon-dark d-block my-2">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                ></SVG>
              </span>
              <a
                href="#"
                className="text-dark font-weight-bold font-size-h6 mt-2"
              >
                Carrier Clicks
              </a>
            </div>
          </div> */}

          {/* <div className="row m-0 mt-4">
            <div className="col bg-light-success px-6 py-8 rounded-xl">
              <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Plus.svg")}
                ></SVG>
              </span>
              <a
                href="#"
                className="text-success font-weight-bold font-size-h6 mt-2"
              >
                Log In
              </a>
            </div>
          </div> */}
        </div>

        {/* Resize */}
        <div className="resize-triggers">
          <div className="expand-trigger">
            <div style={{ width: "411px", height: "461px" }} />
          </div>
          <div className="contract-trigger" />
        </div>
      </div>
    </div>
  );
}
