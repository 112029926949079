import React, { useState } from "react";
import Utils from "../../components/shared/Utils";
import $ from "jquery";
import NinjaProgressSpinner from "../../components/shared/NinjaProgressSpinner";
import { useEffect } from "react";

export default function Settings(props) {
  const utils = new Utils(); // To create instance
  // for confirmation modal
  const [getUserInf, setGetUserInf] = useState({});
  const [loader, setLoader] = useState(false);
  const [userToManage, setUserToManage] = useState({});
  const [success, setSuccess] = useState({});
  const [msg403, setMsg403] = useState("");
  const [error, setError] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);

  // console.log("userToManage", userToManage);

  const handleChange = (e) => {
    setUserToManage({
      ...userToManage,
      [e.target.name]: e.target.value,
    });
  };
  // user.get API Call
  function getUserInfo() {
    setBtnDisable(false);
    setLoader(true);
    $("#spinnerWrap").css("display", "flex");
    $("#ID_NINJA_SPINNER_IN_PROGRESS").show();
    let url = utils.getServicesUrl() + "/v1/user.get";
    let token = utils.readCookie("token");
    let username = utils.readCookie("email");

    var myHeaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    var data = JSON.stringify({
      username: username,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: data,
      redirect: "follow",
    };

    var statusCode;
    fetch(url, requestOptions)
      .then((response) => {
        statusCode = response.status;
        return response.json();
      })
      .then((result) => {
        if (statusCode === 403) {
          setError(true);
          setBtnDisable(true);
          setMsg403("You don't have access to this feature.");
        } else {
          setGetUserInf(result);
          setBtnDisable(false);
        }
        // console.log("setGetUserInf", result);
        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        $("#spinnerWrap").css("display", "none");
        setLoader(false);
      })
      .catch((error) => {
        // console.log('error', error)
        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        $("#spinnerWrap").css("display", "none");
        setLoader(false);
        // alert(error);
      });
  }
  // End
  // Api define upDate User
  function upDateUser(e) {
    setMsg403("");
    setSuccess({});
    setError(false);
    e.preventDefault();
    // sinner call
    setLoader(true);
    $("#spinnerWrap").css("display", "flex");
    $("#ID_NINJA_SPINNER_IN_PROGRESS").show();
    let url = utils.getServicesUrl() + "/upDateUser";
    let token = utils.readCookie("token");
    let email = utils.readCookie("email");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    var data = JSON.stringify({
      firstName: userToManage.firstName
        ? userToManage.firstName
        : getUserInf.userInfo.firstName,
      lastName: userToManage.lastName
        ? userToManage.lastName
        : getUserInf.userInfo.lastName,
      user_id: getUserInf.userInfo.userId,
      email: userToManage.email
        ? userToManage.email
        : getUserInf.userInfo.email,
      userName: userToManage.userName
        ? userToManage.userName
        : getUserInf.userInfo.username,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: data,
      redirect: "follow",
    };

    var status;
    fetch(url, requestOptions)
      .then((response) => {
        status = response.status;
        return response.json();
      })
      .then((result) => {
        if (status === 403) {
          setMsg403("You don't have access to this feature.");
          setError(true);
        } else if (status === 400) {
          setSuccess(result);
          setError(true);
        } else if (status === 200) {
          setSuccess(result);
          setError(false);
          timerSetForLogout(10);
          setTimeout(() => {
            utils.logOut();
          }, 11000);
        } else {
          setSuccess(result);
          setError(false);
        }
        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        $("#spinnerWrap").css("display", "none");
        setLoader(false);
      })
      .catch((error) => {
        // console.log("error", error);
        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        $("#spinnerWrap").css("display", "none");
        setError(error);
        setLoader(false);
        // alert(error);
      });
  }
  // End

  const redirectUrl = () => {
    window.location.href = "/admin-dashboard";
  };
  // Set time for logout
  function timerSetForLogout(timeleft) {
    var downloadTimer = setInterval(function() {
      if (timeleft <= 0) {
        clearInterval(downloadTimer);
        document.getElementById("countdown").innerHTML = "Finished";
      } else {
        document.getElementById("countdown").innerHTML =
          timeleft + " seconds remaining";
      }
      timeleft -= 1;
    }, 1000);
  }
  // End

  useEffect(() => {
    window.addEventListener("load", redirectUrl, false);
    getUserInfo();
  }, []);

  return (
    <div>
      <div id="spinnerWrap">
        <NinjaProgressSpinner maxWidth="100px" maxHeight="100px" />
      </div>
      <section>
        <div className={`card card-custom ${loader ? "isLoaderGen" : ""}`}>
          <div className="card-header">
            <h3 className="card-title">Personal Info:</h3>
          </div>
          <form className="form" onSubmit={(e) => upDateUser(e)}>
            <div className="card-body">
              <div className="form-group row">
                <div className="col-12 col-sm-6">
                  <label>First Name</label>
                  <input
                    type="text"
                    onChange={(e) => handleChange(e)}
                    class="form-control form-control-sm"
                    placeholder="first name"
                    name="firstName"
                    defaultValue={
                      getUserInf.userInfo && getUserInf.userInfo.firstName
                    }
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <label>Last Name</label>
                  <input
                    type="text"
                    onChange={(e) => handleChange(e)}
                    className="form-control form-control-sm"
                    placeholder="last name"
                    name="lastName"
                    defaultValue={
                      getUserInf.userInfo && getUserInf.userInfo.lastName
                    }
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-6">
                  <label>User Name:</label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="user name"
                    onChange={(e) => handleChange(e)}
                    name="userName"
                    defaultValue={
                      getUserInf.userInfo && getUserInf.userInfo.username
                    }
                  />
                  <div>
                    <span className={error ? "text-danger" : "text-success"}>
                      {error && success.userName}
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <label>Email</label>
                  <input
                    type="email"
                    onChange={(e) => handleChange(e)}
                    className="form-control form-control-sm"
                    placeholder="email"
                    name="email"
                    defaultValue={getUserInf.userInfo && getUserInf.userInfo.email}
                  />
                  <div>
                    <span className={error ? "text-danger" : "text-success"}>
                      {error && success.email}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="text-center">
                <span className={error ? "text-danger" : "text-success"}>
                  {btnDisable
                    ? msg403
                    : success.message && success.message + "Login again in 10s"}
                </span>
                <div className="text-danger" id="countdown"></div>
              </div>
              <button
                disabled={btnDisable}
                type="submit"
                class="btn commonButtonClass mr-2"
              >
                Update info
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}
