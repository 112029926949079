import React, { Component, useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Button } from 'primereact/components/button/Button'
import $ from 'jquery'
import ReactDOMServer from 'react-dom/server'
import ReactDOM from 'react-dom'
import '../../../src/App.css'
import { NinjaRaterService } from '../../service/NinjaRaterService'
import Utils from '../../components/shared/Utils'
import NinjaProgressSpinner from '../../components/shared/NinjaProgressSpinner'

const utils = new Utils()

const AddUser = (props) => {
  let masterUserId = utils.readCookie('userId')
  const [userValues, setUserValues] = useState([])
  const [cEmail, setCEmail] = useState(null)
  const [message, setMessage] = useState("")
  const [errorShow, setErrorShow] = useState(false)

  const token = utils.readCookie('token')
  useEffect(() => {
    token && getAllSubUsers()
    $('#ID_NINJA_SPINNER_IN_PROGRESS').show()
  }, [])

  const addNewSubUser = (userDetails) => {
    let url = utils.getServicesUrl() + '/addSubUser'
    $('#ID_NINJA_SPINNER_IN_PROGRESS').show()
    utils.ajaxRequest('POST', url, success, error, userDetails)
  }

  const updateSubUser = (userDetails) => {
    let url = utils.getServicesUrl() + '/upDateUser'
    $('#ID_NINJA_SPINNER_IN_PROGRESS').show()
    utils.ajaxRequest('POST', url, updateSuccess, updateError, userDetails)
  }

  const getAllSubUsers = (userDetails) => {
    let user = {
      user_id: masterUserId,
    }
    let url = utils.getServicesUrl() + '/getAllSubUsersByMasterId'
    utils.ajaxRequest('POST', url, successAll, errorAll, JSON.stringify(user))
  }

  const success = () => {
    $('#ID_NINJA_SPINNER_IN_PROGRESS').hide()
    setErrorShow(false)
    setMessage("User added")
    // window.location.reload()
  }
  const updateSuccess = () => {
    $('#ID_NINJA_SPINNER_IN_PROGRESS').hide()
    setErrorShow(false)
    setMessage("User updated")
    // window.location.reload()
  }
  const deleteSuccess = (rem) => {
    rem.remove();
    $('#ID_NINJA_SPINNER_IN_PROGRESS').hide()
    setErrorShow(false)
    setMessage("User deleted")
    // window.location.reload()
  }
  const error = () => {
    $('#ID_NINJA_SPINNER_IN_PROGRESS').hide()
    setErrorShow(true)
    setMessage("User not added")
  }
  const updateError = () => {
    $('#ID_NINJA_SPINNER_IN_PROGRESS').hide()
    setErrorShow(true)
    setMessage("User not updated")
  }
  const deleteError = () => {
    $('#ID_NINJA_SPINNER_IN_PROGRESS').hide()
    setErrorShow(true)
    setMessage("user not deleted")
  }
  const errorAll = () => {
    $('#ID_NINJA_SPINNER_IN_PROGRESS').hide()
    setMessage("User not added yet!")
  }
  const successAll = (response) => {
    $('#ID_NINJA_SPINNER_IN_PROGRESS').hide()
    if (response.length > 2) {
      response.pop()
    }
    setUserValues(response)
  }

  const settingValues = (temp) => {
    let userDetails = {
      userName: temp[0],
      pswd: temp[1],
      firstName: temp[2],
      lastName: temp[3],
      email: temp[4],
      master_user_id: masterUserId,
    }
    addNewSubUser(JSON.stringify(userDetails))
  }

  const handleClick = (e) => {
    e.preventDefault()
    let row =
      '<tr>' +
      '<td><input type="text" class="form-control" name="userName" id="userName"></td>' +
      '<td><input type="text" class="form-control" name="pswd" id="pswd"></td>' +
      '<td><input type="text" class="form-control" name="firstName" id="firstName"></td>' +
      '<td><input type="text" class="form-control" name="lastName" id="lastName"></td>' +
      '<td><input type="text" class="form-control" name="email" id="email"></td>' +
      '<td><a class="add" title="Add" style="display: block"><i class="material-icons">&#xE03B;</i></a><a class="edit" title="Edit" ><i class="material-icons">&#xE254;</i></a><a class="delete" title="Delete" onClick={handleAddRow}><i class="material-icons">&#xE872;</i></a></td>' +
      '</tr>'
    if (document.querySelectorAll('.add-user tr').length < 3) {
      let newRow = document.querySelectorAll('.add-user')[0].insertRow()
      newRow.innerHTML = row
      document.querySelectorAll('.add-user .delete').forEach((element) => {
        element.addEventListener('click', function () {
          this.parentElement.parentElement.remove()
        })
      })
      document.querySelectorAll('.add-user .add').forEach((element) => {
        element.addEventListener('click', function () {
          let temp = []
          this.parentElement.parentElement.childNodes.forEach((q) => {
            temp.push(q.children[0].value)
          })
          temp.pop()
          settingValues(temp)
        })
      })
    } else {
      alert("you can't add more than 2 users")
    }
  }

  const handleEdit = (event) => {
    setMessage("You can only change the first name and last name!")
    event.target.parentElement.style.display = 'none'
    event.target.parentElement.parentElement.children[0].style.display = 'block'
    let row = event.target.parentNode.parentNode.parentNode
    setCEmail(row.children[4].textContent)
    for (let i = 0; i < row.children.length - 1; i++) {
      let value = row.children[i].textContent
      row.children[i].innerHTML =
        '<input type="text" class="form-control" value="' + value + '">'
    }
  }

  const handleUpdate = (event) => {
    setMessage("")
    let temp = []
    let userDetails = {}
    event.target.parentElement.parentElement.parentElement.childNodes.forEach(
      (q) => {
        temp.push(q.children[0].value)
      },
    )
    temp.pop()
    let currentUser = userValues.filter((v) => v.email == cEmail)
    temp[1] == ''
      ? (userDetails = {
          userName: temp[0],
          firstName: temp[2],
          lastName: temp[3],
          email: temp[4],
          user_id: currentUser[0].userId,
        })
      : (userDetails = {
          userName: temp[0],
          pswd: temp[1],
          firstName: temp[2],
          lastName: temp[3],
          email: temp[4],
          user_id: currentUser[0].userId,
        })
    updateSubUser(JSON.stringify(userDetails))
  }

  const handleDelete = (e, subUserId) => {
    setMessage("");
    let rem = e.target.parentElement.parentElement.parentElement;
    let url = utils.getServicesUrl() + '/deleteSubByUserId'
    let data = {
      "master_user_id":masterUserId,
      "user_id":subUserId
    }
    $('#ID_NINJA_SPINNER_IN_PROGRESS').show()
    utils.ajaxRequest('POST', url, ()=>deleteSuccess(rem), deleteError, JSON.stringify(data))
  }

  const test = () => {}

  return (
    <div className="ui-g-12 no-padding">
      <NinjaProgressSpinner
        maxWidth="200px"
        maxHeight="200px"
        marginTop="-10px"
      />
      <p className={['previous_head', 'commonButtonClass'].join(' ')}>
        Add User
      </p>

      <div
        id="main"
        className="container sidebar-none sidebar-divider-vertical"
      >
        <div className="wf-wrap">
          <div className="wf-container-main">
            <div id="content" className="content" role="main">
              <div>
                <Button
                  className="commonButtonClass add-new"
                  type="button"
                  style={{
                    float: 'right',
                    marginBottom: '15px',
                  }}
                  label="Add User"
                  id="add_user_id"
                  onClick={(e) => handleClick(e)}
                ></Button>
                {test()}
              </div>
              <div className="text-center">
                  <span className={` h5 ${errorShow ? "text-danger" : "text-success"}`}>
                    {message}
                  </span>
              </div>
              <div className="table-responsive">
                <table className="table table-bordered add-user">
                  <thead className="thead-light">
                    <tr>
                      <th>User Name</th>
                      <th>Password</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userValues.length > 0 &&
                      userValues.map((item, index) => {
                        test()
                        return (
                          <tr key={index}>
                            <td>{item.username}</td>
                            <td
                              style={{ background: 'silver', opacity: '0.3' }}
                            >
                              <span
                                class="fas fa-lock"
                                style={{ marginLeft: '60px' }}
                              ></span>
                            </td>
                            <td>{item.firstName}</td>
                            <td>{item.lastName}</td>
                            <td>{item.email}</td>
                            <td>
                              <a class="add" title="Add">
                                <i
                                  class="material-icons"
                                  onClick={handleUpdate}
                                >
                                  &#xE03B;
                                </i>
                              </a>
                              <a class="edit" title="Edit">
                                <i class="material-icons" onClick={handleEdit}>
                                  &#xE254;
                                </i>
                              </a>
                              <a
                                class="delete"
                                title="Delete"
                                onClick={(e)=>handleDelete(e,item.userId)}
                              >
                                <i class="material-icons">&#xE872;</i>
                              </a>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
              <small>Add up to 2 users</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(null, null)(withRouter(AddUser))
