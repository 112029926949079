import React, { Component } from 'react'
import styles from './register.module.scss'
// import { SelectDropDown } from '../../shared/inputFields'
// import { MenuItem } from '@material-ui/core'
// import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
// import { CustomisedTextfield, Customisedbutton } from '../../shared/inputFields'
// import { Checkbox } from '@material-ui/core'
import { bindActionCreators } from 'redux'
import { createAccountActions } from '../../state/ducks/createAccount/createAccountActions'
import { paymentPageValidator } from './createAccountValidator'
import {
  resetFormValidations,
  updateFormValidations,
} from '../../shared/components/commonValidations'
import { Elements, StripeProvider } from 'react-stripe-elements'
import * as Constants from '../../Constants'
import SplitForm from './StripeElemetsForCreditCard'
class Payment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedAccountType: '',
      selectedPaymentType: '',
      routingNumber: '',
      accountNumber: '',
      nameOnAccount: '',
      cvs: '',
      termsAndSubFee: '',
      acceptTermsAndCond: false,
    }
  }
  Next = async (event) => {
    let validFileds = []
    try {
      Object.keys(this.state).forEach((item) => {
        let isvalid = updateFormValidations(
          item,
          this.state[item],
          paymentPageValidator,
        )
        if (!isvalid) validFileds.push(isvalid)
        this.setState((state) => {
          this.state[item] = this.state[item]
          return state
        })
      })
      if (validFileds.length <= 0) {
      }
    } catch (expection) {
      // console.log(expection)
    }
  }

  onSelectedAccountType = (key, event) => {
    this.setState((state) => {
      this.state[key] = event.target.value
      return state
    })
  }

  updateInputValue = (name, event) => {
    const value =
      name === 'acceptTerms'
        ? !this.state.acceptTermsAndCond
        : event.target.value
    this.setState((state) => {
      this.state[name] = value
      return state
    })
  }
  componentDidMount() {
    resetFormValidations(paymentPageValidator)
  }
  UNSAFE_componentWillMount() {
    // console.log('window', window.Stripe('pk_test_51HeHd5JhuQxu88ulu2fBQP3BX82UMFecNIP8cHhXZy32uiQIdLSWzZ8GfARxD5WFdDHuol6zjLwtWUOV2xrmnOus00qCBpaWtJ'));
    // let stripe = window.Stripe(Constants.STRIPE_TOKEN_TEST);
    // enable below code while moving code to prod
    // let stripe = Constants.IS_PRODUCTION ? window.Stripe(Constants.STRIPE_TOKEN_LIVE)
    //   : window.Stripe(Constants.STRIPE_TOKEN_TEST)
    // this.setState({
    //   ...this.props.createAccountObject,
    //   stripe: stripe
    // });

    //For avoiding error if stripe element is not loaded

    if (window.Stripe) {
      if (Constants.IS_PRODUCTION) {
        this.setState({
          ...this.props.createAccountObject,
          stripe: window.Stripe(Constants.STRIPE_TOKEN_LIVE),
        })
      } else {
        this.setState({
          ...this.props.createAccountObject,
          stripe: window.Stripe(Constants.STRIPE_TOKEN_TEST),
        })
      }
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        if (Constants.IS_PRODUCTION) {
          this.setState({
            ...this.props.createAccountObject,
            stripe: window.Stripe(Constants.STRIPE_TOKEN_LIVE),
          })
        } else {
          this.setState({
            ...this.props.createAccountObject,
            stripe: window.Stripe(Constants.STRIPE_TOKEN_TEST),
          })
        }
      })
    }
  }
  render() {
    // const accountTypes = [
    //   { ID: 0, reason: 'Account Type' },
    //   { ID: 1, reason: 'Checking' },
    //   { ID: 2, reason: 'Savings' },
    // ]
    // const creditTypes = [
    //   { ID: 0, reason: 'Credit Card or ACH' },
    //   { ID: 1, reason: 'Credit Card' },
    //   { ID: 2, reason: 'ACH' },
    // ]
    const {
      // selectedAccountType,
      // selectedPaymentType,
      // routingNumber,
      // accountNumber,
      // nameOnAccount,
      // cvs,
      // termsAndSubFee,
      // acceptTermsAndCond,
      stripe,
    } = this.state
    // console.log('this.state', this.state)
    const { chosenPlan } = this.props.createAccountObject
    // console.log('chosenPlan.planPrice', chosenPlan)
    return (
      <div style={{ padding: '2% 2% 2% 0%' }} className={styles.payment_sec}>
        <div id="paymentSecondHead" className={styles.desc_selected_pln}>
          {chosenPlan.planName === 'Annually' ? (
            <span style={{fontSize:'20px',fontWeight:'300'}}>${chosenPlan.planPrice}<span style={{fontSize:'15px',fontWeight:'200'}}>/annual 1 month free!</span> </span>
          ) : chosenPlan.planName === 'Monthly' ? (
            <span style={{fontSize:'20px',fontWeight:'300'}}>${chosenPlan.planPrice}<span style={{fontSize:'15px',fontWeight:'200'}}>/month</span> </span>
          ) : (
            <span style={{fontSize:'20px',fontWeight:'300'}}>{chosenPlan.planName}</span>
          )}
        </div>
        <p className={styles.bank_acc_desc}>Bank Account Information:</p>
        <span className={styles.desclaimer_desc}>
          The subscription can be cancelled at anytime and is billed on a
          monthly basis, unless the annual option has been selected.
        </span>
        <section className={styles.card_main_sec}>
          <div id="resPaymentWidth" style={{ width: '825px' }} className={styles.Checkout}>
            <StripeProvider stripe={stripe}>
              <Elements>
                <SplitForm
                  nextPage={this.Next.bind(this)}
                  chosenPlan={chosenPlan}
                />
              </Elements>
            </StripeProvider>
          </div>
          {/* <div className={styles.each_label_sec_button}>
            <Customisedbutton
              name="Next"
              className={styles.custom_button}
              onClick={this.Next.bind(this)}
            />
          </div> */}
        </section>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    createAccountObject: state.createAccountReducer,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      createAccount: bindActionCreators(createAccountActions, dispatch),
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Payment)
