import React, { useMemo } from "react";
import { useState } from "react";
import $ from "jquery";
import SVG from "react-inlinesvg";
import NinjaProgressSpinner from "../../components/shared/NinjaProgressSpinner";
import Utils from "../../components/shared/Utils";
import { toAbsoluteUrl } from "../_helpers";
import { useEffect } from "react";
import PaginationGen from "./PaginationGen";
// import { Table } from 'react-bootstrap'

export default function Subscription() {
  const utils = new Utils(); // To create instance
  const [state, setstate] = useState({}); // State of this component
  const [loader, setLoader] = useState(false);
  const [prceId, setPrceId] = useState("");
  // Plans
  const [specialOfferPlan, setSpecialOfferPlan] = useState("");
  const [monthlyPlan, setMonthlyPlan] = useState("");
  const [anuallyPlan, setAnuallyPlan] = useState("");
  // End
  // console.log('env', process.env.REACT_APP_ANUALLY_PRICE_ID)

  // getSubscribtionInfoByPriceId define
  function getSubscribtionInfoByPriceId(page, priceId) {
    // sinner call
    $("#spinnerWrap").css("display", "flex");
    $("#ID_NINJA_SPINNER_IN_PROGRESS").show();
    setLoader(true);
    // End
    let urlByPriceId = utils.getServicesUrl() + "/getUsersWithSubscribtionInfo";
    let token = utils.readCookie("token");
    var myHeaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    let dt = {
      userName: "",
      planId: "",
      priceId: priceId,
      fromDate: "",
      toDate: "",
      pageNumber: page,
      status: "",
    };
    var data = JSON.stringify(dt);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: data,
      redirect: "follow",
    };

    fetch(urlByPriceId, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setstate(result);
        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        setLoader(false);
        $("#spinnerWrap").css("display", "none");
      })
      .catch((error) => {
        // console.log('error', error)
        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        setLoader(false);
        $("#spinnerWrap").css("display", "none");
        // alert(error);
      });
  }
  // End

  // getProducts define
  function getProducts() {
    // sinner call
    $("#spinnerWrap").css("display", "flex");
    $("#ID_NINJA_SPINNER_IN_PROGRESS").show();
    setLoader(true);
    // End
    let urlByPriceId = utils.getServicesUrl() + "/getProducts";
    let token = utils.readCookie("token");
    var myHeaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(urlByPriceId, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        result.map((v) => {
          if (v.productName === "First two months FREE!") {
            setSpecialOfferPlan(v.priceId);
          } else if (v.productName === "Monthly") {
            setMonthlyPlan(v.priceId);
          } else if (v.productName === "Annually") {
            setAnuallyPlan(v.priceId);
          }
        });

        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        setLoader(false);
        $("#spinnerWrap").css("display", "none");
      })
      .catch((error) => {
        // console.log('error', error)
        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        setLoader(false);
        $("#spinnerWrap").css("display", "none");
        // alert(error);
      });
  }
  // End

  const handleSubmit = (event, priceId) => {
    // alert(priceId)
    event.preventDefault();
    setPrceId(priceId);
    getSubscribtionInfoByPriceId(0, priceId);
  };

  const unixDate = (unix) => {
    var a = new Date(unix);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var time = month + " " + date + "," + " " + year;
    return time;
  };

  // capitalizeFirstLetter
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  // End

  const redirectUrl = () => {
    window.location.href = "/admin-dashboard";
  };

  useEffect(() => {
    getSubscribtionInfoByPriceId(0, "");
    getProducts();
    window.addEventListener("load", redirectUrl, false);
  }, []);

  return (
    <>
      <div>
        <h1 style={{ marginBottom: "30px" }}>Subscription Plans</h1>
        <div id="spinnerWrap">
          <NinjaProgressSpinner
            maxWidth="100px"
            maxHeight="100px"
            marginTop="-10px"
          />
        </div>
        <div
          className={`row text-center ${loader ? "isLoaderGen" : ""}`}
          style={{ maxWidth: "none" }}
        >
          <div
            className="col-11 col-sm-11 col-md-5 col-lg-3 col-xl-3 col-xxl-3 bg-light-primary  rounded-xl ml-5 mb-5"
            style={{ height: "240px" }}
          >
            <span className="d-block my-2">
              <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/renew.svg"
                  )}
                ></SVG>
              </span>
              <h1
                className="text-primary"
                style={{ marginLeft: "5px", fontSize: "20px" }}
              >
                SPECIAL OFFER
              </h1>
            </span>
            <h6 className="text-muted">First two months FREE!</h6>
            <button
              type="button"
              onClick={(event) => handleSubmit(event, specialOfferPlan)}
              className="btn btn-light-success btn-lg"
              style={{ fontWeight: "bold", marginTop: "30px" }}
            >
              View Subscription
            </button>
          </div>
          <div
            className="col-11 col-sm-11 col-md-5 col-lg-3 col-xl-3 col-xxl-3 bg-light-primary  rounded-xl ml-5 mb-5"
            style={{ height: "240px" }}
          >
            <span className="d-block my-2">
              <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/subscription.svg"
                  )}
                ></SVG>
              </span>
              <h1
                className="text-primary"
                style={{ marginLeft: "5px", fontSize: "20px" }}
              >
                Monthly
              </h1>
            </span>
            <h6 className="text-muted">$19.99/Month</h6>

            <button
              type="button"
              onClick={(event) => handleSubmit(event, monthlyPlan)}
              className="btn btn-light-success btn-lg"
              style={{ fontWeight: "bold", marginTop: "30px" }}
            >
              View Subscription
            </button>
          </div>
          <div
            className="col-11 col-sm-11 col-md-5 col-lg-3 col-xl-3 col-xxl-3 bg-light-primary  rounded-xl ml-5 mb-5"
            style={{ height: "240px" }}
          >
            <span className="d-block my-2">
              <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/subscription.svg"
                  )}
                ></SVG>
              </span>
              <h1
                className="text-primary"
                style={{ marginLeft: "5px", fontSize: "20px" }}
              >
                Annually
              </h1>
            </span>
            <h6 className="text-muted">$220/Year 1 month FREE!</h6>

            <button
              type="button"
              onClick={(event) => handleSubmit(event, anuallyPlan)}
              className="btn btn-light-success btn-lg"
              style={{ fontWeight: "bold", marginTop: "30px" }}
            >
              View Subscription
            </button>
          </div>
        </div>
        <div class={`table-responsive ${loader ? "isLoaderGen" : ""} `}>
          <table
            id="kt_advance_table_widget_1"
            className="table table-head-custom  table-vertical-center col-11"
            style={{ maxWidth: "100%" }}
          >
            <thead>
              <tr>
                <th>User Name</th>
                <th>Plan Name</th>
                <th>Status</th>
                <th>Subscription Date</th>
                <th>Trial Satart Date</th>
                {/* <th>Trial Period Days</th> */}
                <th>Trial End Date</th>
              </tr>
            </thead>
            <tbody>
              {state.content &&
                state.content.map((val, ind) => (
                  <tr key={ind}>
                    <td>{val.username}</td>
                    <td>
                      {val.planName == null || val.planName == ""
                        ? "null"
                        : val.planName}
                    </td>
                    <td>
                      <span
                        style={{
                          fontWeight: "600",
                          padding: "2px 4px 4px 4px",
                          borderRadius: "0px",
                        }}
                        className={`badge   ${
                          val.subStatus.toLowerCase() == "active"
                            ? "badge-success"
                            : ""
                        } ${
                          val.subStatus.toLowerCase() == "suspend"
                            ? "badge-warning"
                            : ""
                        } ${
                          val.subStatus.toLowerCase() == "cancel"
                            ? "badge-danger"
                            : ""
                        }  `}
                      >
                        {val.subStatus && capitalizeFirstLetter(val.subStatus)}
                      </span>
                    </td>
                    <td>{unixDate(val.createdAt)}</td>
                    <td>{unixDate(val.trialStartDate)}</td>
                    {/* <td>{val.trialPeriodDays}</td> */}
                    <td>{unixDate(val.trialEndDate)}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {state.pageable && (
          <PaginationGen
            prceId={prceId}
            page={state.pageable.pageNumber}
            totalPage={state.totalPages}
            getUsersWithSubscribtionInfo={getSubscribtionInfoByPriceId}
          />
        )}
      </div>
    </>
  );
}
