import React, { Component, Suspense, lazy } from "react";
import NinjaRaterApp from "./NinjaRaterApp";
import App from "./app/App";
import AdminIndex from "./admin_index";
import { DashboardPage } from "./app/pages/DashboardPage";
import { Layout } from "./_metronic/layout";
import { LayoutSplashScreen, ContentRoute } from "./_metronic/layout";
import Utils from "./components/shared/Utils";
const utils = new Utils();

const SwitchApp = () => {
  let email = utils.readCookie("email");
  let admin = false;
  const redirectUrl = () => {
    window.location.href = "/admin-dashboard";
  };
  if (email == "kateyanov" || email == "sarabullock") {
    admin = true;
    window.addEventListener("load", redirectUrl, false);
  } else {
    admin = false;
  }

  return <>{admin ? <AdminIndex /> : <NinjaRaterApp />}</>;
};

export default SwitchApp;
