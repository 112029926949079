import {ajax} from "rxjs/ajax";
import Utils from "../../components/shared/Utils";

const utils = new Utils()

export class createAccountService {
    constructor () { }

    static getSubscriptionPlans(action) {
        const source$ = ajax.get(
            utils.getServicesUrl() + "/getProducts",
            action.headers,
            action.payload
            
        );
        return source$;
    }
    static createUser(action) {
        // console.log("Action check", action.payload )
        const source$ = ajax.post(
            utils.getServicesUrl() + "/newJackalopeUser",
            action.payload,
            action.headers
            
        );
        return source$;
    }
}


