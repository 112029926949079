import React, { Component } from "react";
import { withRouter } from "react-router";
import styles from "../v1-components/register/register.module.scss";
import { Customisedbutton } from "../shared/inputFields";
import * as Constants from "../Constants";
import PreviousSubmissions from "../v1-components/previousSubmissions";
import "../../src/App.css";
import $ from "jquery";
import SwiftSlider from "react-swift-slider";
import "./SliderHome.css";
const data = [
  { id: "1", src: "/images/allan2.jpg" },
  { id: "2", src: "/images/allan3.jpg" },
];

class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <section className={styles.dasborad_sec}>
          <div className="slider_content">
            <h2
              className="homeWellcome"
              style={{
                font:
                  "normal bold 44px/54px 'Raleway',Helvetica,Arial,Verdana,sans-serif",
              }}
            >
              Welcom<span className={styles.bottom_borderline}>e to Ja</span>
              ckalope
            </h2>
            <div className={styles.banner_buttons}>
              <Customisedbutton
                name="Previous Submissions"
                className={[
                  styles.dashboard_button_one22,
                  "commonButtonClass",
                  "mt-4",
                ].join(" ")}
                onClick={(event) =>
                  this.props.ninjaRaterApp.NinjaRaterAppStateHandler(
                    event,
                    Constants.ACTION_PREVIOUS_SUBMISSIONS
                  )
                }
              />
              <Customisedbutton
                name="Get New Indication"
                className={[
                  styles.dashboard_button_one22,
                  "commonButtonClass",
                  "mt-4",
                ].join(" ")}
                // className={styles.dashboard_button_one22}
                onClick={(event) =>
                  this.props.ninjaRaterApp.gotoIndications(event)
                }
              />
            </div>
          </div>
          <SwiftSlider
            className="slider"
            data={data}
            showDots={false}
            height={650}
          />
        </section>
      </React.Fragment>
    );
  }
}

export default Home;
