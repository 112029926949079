import React from "react";
import Utils from "../../components/shared/Utils";
import { Modal } from "react-bootstrap";
import { Button } from "primereact/components/button/Button";
import { useState } from "react";

export default function SubscriberSubUser(props) {
  const utils = new Utils();
  const [state, setstate] = useState({ ...props });
  // console.log("SubscriberSubUser props", state);

  const handleClose = () => {
    state.setSubscriberSubUserModel(false);
  };

  return (
    <div>
      <Modal
        show={state.subscriberSubUserModel && state.subscriberSubUserModel}
        backdrop="static"
        keyboard={false}
        style={{ maxWidth: "fitContent" }}
      >
        <div className="text-success text-danger"></div>

        <>
          <Modal.Header>
            <Modal.Title>Subscriber Details </Modal.Title>
            <span id="closeIcon" onClick={() => handleClose()}>
              ✕
            </span>
          </Modal.Header>
          <Modal.Body>
            <table>
              <thead></thead>
              <tbody style={{ border: "none" }}>
                <tr>
                  <th style={{ width: "120px" }} scope="row">
                    User Name:{" "}
                  </th>
                  <td>{state.subscriberDetail.userName}</td>
                </tr>
                <tr style={{ backgroundColor: "white" }}>
                  <th style={{ width: "120px" }} scope="row">
                    Email:{" "}
                  </th>
                  <td>{state.subscriberDetail.email}</td>
                </tr>
                <tr>
                  <th style={{ width: "120px" }} scope="row">
                    User Id:{" "}
                  </th>
                  <td>{state.subscriberDetail.userId}</td>
                  <td></td>
                </tr>
                <tr style={{ backgroundColor: "white" }}>
                  <th style={{ width: "120px" }} scope="row">
                    Total Sub Users
                  </th>
                  <td>
                    <h5 style={{ margin: "0px" }}>
                      <span
                        style={{
                          fontWeight: "600",
                          borderRadius: "0px",
                          padding: "2px 0px",
                        }}
                        className="badge badge-secondary"
                      >
                        {state.subscriberSubUserModelState.length}
                      </span>
                    </h5>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div
              className="row align-items-baseline"
              style={{ marginBottom: "20px" }}
            >
              <h4 className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                Sub users detail:{" "}
              </h4>
            </div>
            <div className="subActTabRes">
              <table>
                <thead>
                  <tr>
                    <th className="text-center" style={{ color: "#8b8d94" }}>
                      Sr#
                    </th>
                    <th className="text-center" style={{ color: "#8b8d94" }}>
                      Name
                    </th>
                    <th className="text-center" style={{ color: "#8b8d94" }}>
                      Phone#
                    </th>
                    <th className="text-center" style={{ color: "#8b8d94" }}>
                      Email
                    </th>
                    <th className="text-center" style={{ color: "#8b8d94" }}>
                      Plan Name
                    </th>
                    <th className="text-center" style={{ color: "#8b8d94" }}>
                      Address
                    </th>
                    <th className="text-center" style={{ color: "#8b8d94" }}>
                      City
                    </th>
                    <th className="text-center" style={{ color: "#8b8d94" }}>
                      State
                    </th>
                    <th className="text-center" style={{ color: "#8b8d94" }}>
                      Country
                    </th>
                    <th className="text-center" style={{ color: "#8b8d94" }}>
                      Create Date
                    </th>
                    <th className="text-center" style={{ color: "#8b8d94" }}>
                      Update Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {state.subscriberSubUserModelState.map((val, ind) => (
                    <tr key={ind + 1}>
                      <th className="text-center" scope="row">
                        {ind + 1}
                      </th>
                      <td className="text-center">
                        {val.firstName + " " + val.lastName}
                      </td>
                      <td className="text-center">
                        {val.phone === null || val.phone === ""
                          ? "null"
                          : val.phone}
                      </td>
                      <td className="text-center">
                        {val.email === null || val.email === ""
                          ? "null"
                          : val.email}
                      </td>
                      <td className="text-center">
                        {val.planName === null || val.planName === ""
                          ? "null"
                          : val.planName}
                      </td>
                      <td className="text-center">
                        {val.streetAddress === null || val.streetAddress === ""
                          ? "null"
                          : val.streetAddress}
                      </td>
                      <td className="text-center">
                        {val.city === null || val.city === ""
                          ? "null"
                          : val.city}
                      </td>
                      <td className="text-center">
                        {val.state === null || val.state === ""
                          ? "null"
                          : val.state}
                      </td>
                      <td className="text-center">
                        {val.country === null || val.country === ""
                          ? "null"
                          : val.country}
                      </td>
                      <td className="text-center">
                        {val.createdAt === null || val.createdAt === ""
                          ? "null"
                          : val.createdAt}
                      </td>
                      <td className="text-center">
                        {val.updatedAt === null || val.updatedAt === ""
                          ? "null"
                          : val.updatedAt}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </>
      </Modal>
    </div>
  );
}
