import React, { useMemo, useState, useEffect } from "react";
import Utils from "../../components/shared/Utils";
import $ from "jquery";
import NinjaProgressSpinner from "../../components/shared/NinjaProgressSpinner";
import { template } from "lodash";

export default function Carriers() {
  const utils = new Utils(); // To create instance
  const [state, setstate] = useState(); // State of this component
  const [qury, setqury] = useState("");

  // Api define getParentCompanyWithSubsidiaries
  function getParentCompanyWithSubsidiaries() {
    // sinner call
    $("#spinnerWrap").css("display", "flex");
    $("#ID_NINJA_SPINNER_IN_PROGRESS").show();
    let url = utils.getServicesUrl() + "/getParentCompanyWithSubsidiaries";
    let token = utils.readCookie("token");

    var myHeaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setstate(result);
        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        $("#spinnerWrap").css("display", "none");
      })
      .catch((error) => {
        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        $("#spinnerWrap").css("display", "none");
        // alert(error);
      });
  }
  // End

  const handleDetail = (i) => {
    let detailElems = document.getElementsByClassName(`row-number${i}-detail`);
    let shown = detailElems[0].style;
    if (shown.display === "revert") {
      [...detailElems].map((e) => {
        document.getElementById(`plus${i}`).style.display = "block";
        document.getElementById(`minus${i}`).style.display = "none";
        document.getElementById(`plus${i}`).style.color = "#aef2b0";
        e.style.display = "none";
      });
    } else {
      document.getElementById(`plus${i}`).style.display = "none";
      document.getElementById(`minus${i}`).style.display = "block";
      document.getElementById(`minus${i}`).style.color = "#f67d7d";
      [...detailElems].map((e) => {
        e.style.display = "revert";
      });
    }
  };

  const unixDate = (unix) => {
    var a = new Date(unix);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var time = month + " " + date + "," + " " + year;
    return time;
  };

  // Filter Data
  const filterData = (s) => {
    let q = qury;
    try {
      if (
        s.parentCompanyId.toString().includes(q) ||
        s.parentCompanyName.toUpperCase().includes(q.toUpperCase()) ||
        s.parentCompanyName.toLowerCase().includes(q.toLowerCase())
      ) {
        return s;
      }
    } catch (err) {
      // console.log("err", err.message)
    }
  };
  // End

  const redirectUrl = () => {
    window.location.href = "/admin-dashboard";
  };

  useEffect(() => {
    // Api CALL getUsersWithSubscribtionInfo
    getParentCompanyWithSubsidiaries();

    window.addEventListener("load", redirectUrl, false);
  }, []);

  return (
    <>
      {/* Search Box */}
      <div className="d-flex justify-content-end">
        <form className="navbar-form navbar-right" role="search">
          <div className="input-group align-items-baseline">
            <input
              type="text"
              id="searchBox"
              onChange={(e) => setqury(e.target.value)}
              className="form-control"
              placeholder="Search"
            />
            <i
              style={{ marginLeft: "-29px", zIndex: "1" }}
              className="flaticon-search-magnifier-interface-symbol"
            ></i>
          </div>
        </form>
      </div>
      {/* End */}
      <h1 style={{ marginBottom: "30px" }}>Carriers</h1>
      <div id="spinnerWrap">
        <NinjaProgressSpinner maxWidth="100px" maxHeight="100px" />
      </div>
      <div className="bg-white">
        <div class="row carierTh">
          <div class="col-2"></div>
          <div class="col-2">Carrier Id</div>
          <div class="col-8">Carrier Name</div>
        </div>
        {state &&
          state.filter(filterData).map((val, ind) => (
            <div
              key={ind}
              className="row carierTr"
              id={`main-row-number${ind}`}
            >
              <div className="col-2 text-center">
                <button
                  style={{ position: "relative" }}
                  onClick={() => handleDetail(ind)}
                >
                  <i
                    id={`plus${ind}`}
                    className="ki ki-plus icon-md"
                    style={{ display: "block" }}
                  ></i>
                  <i
                    id={`minus${ind}`}
                    className="ki ki-minus icon-md"
                    style={{ display: "none" }}
                  ></i>
                </button>
              </div>
              <div className="col-2">{val.parentCompanyId}</div>
              <div className="col-8">{val.parentCompanyName}</div>
              <div className="table-responsive">
                <table
                  className={`table row-number${ind}-detail`}
                  style={{ display: "none" }}
                >
                  <thead>
                    <tr className="">
                      <th>Naic Code</th>

                      <th>Subsidary Name</th>
                      <th>Notes</th>
                      <th>Average Accuracy</th>
                      {/* <th>Default Territory Factor</th> */}
                      <th>Create Time</th>
                      <th>Effective Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {val.subsidiaryList.map((v, i) => (
                      <tr key={i}>
                        <td className="text-dark-75 mb-1 font-size-md">
                          {v.naicCode}
                        </td>
                        <td className="text-dark-75 mb-1 font-size-md">
                          {v.subsidaryName}
                        </td>
                        <td className="text-dark-75 mb-1 font-size-md">
                          {v.notes}
                        </td>
                        <td className="text-dark-75 mb-1 font-size-md">
                          {v.averageAccuracy}
                        </td>
                        {/* <td className="text-dark-75 mb-1 font-size-md">
                              {v.defaultTerritoryFactor}
                            </td> */}
                        <td className="text-dark-75 mb-1 font-size-md">
                          {unixDate(v.createTime)}
                        </td>
                        <td className="text-dark-75 mb-1 font-size-md">
                          {unixDate(v.effectiveDate)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
      </div>
    </>
  );
}
