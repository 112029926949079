import React from 'react';
import "./SubscriptionForm.css";
// import {CardElement} from 'react-stripe-elements';
import {CardNumberElement, CardExpiryElement, CardCVCElement, injectStripe} from 'react-stripe-elements';
import '../../assets/ninja/theme/theme-indigo.css';
import '../../assets/ninja/layout/css/layout-indigo.css';
import 'primereact/resources/primereact.min.css';
import styles from "../../v1-components/register/register.module.scss";
const createOptions = (fontSize, padding) => {
	return {
		style: {
			base: {
				fontSize,
				color: '#424770',
				letterSpacing: '0.025em',
				fontFamily: 'ProximaNova-Light, Helvetia, Arial, sans-serif',
				'::placeholder': {
					color: '#aab7c4',
				},
				padding,
				width: '370px'
			},
			invalid: {
				color: '#9e2146',
			},
		},
	};
};

class CardSection extends React.Component {
	
	render() {
		return (
			// <label style={{width: '100%'}} id="ID_LABEL_CARD_ELEMENTS">
			<div
				// style={{width: '100%'}}
				id="ID_LABEL_CARD_ELEMENTS"
				className={styles.formCssUpdateCreditCard}
			>
				<label className={`m-5 ${styles.labelCssUpdateCreditCard}`}>
					Card number
				<CardNumberElement id="ID_CARD_NUMBER"
						// style={{base: {fontSize: '18px'}}}
						{...createOptions(this.props.fontSize)}
					/>
					<div className="stripeimges">
					<img className="mr-1" src="https://js.stripe.com/v3/fingerprinted/img/visa-365725566f9578a9589553aa9296d178.svg" alt="visa" />
					<img className="mr-1" src="https://js.stripe.com/v3/fingerprinted/img/mastercard-4d8844094130711885b5e41b28c9848f.svg" alt="mastercard" />
					<img className="mr-1" src="https://js.stripe.com/v3/fingerprinted/img/amex-a49b82f46c5cd6a96a6e418a6ca1717c.svg" alt="amex" />
					<img className="mr-3" src="https://js.stripe.com/v3/fingerprinted/img/unionpay-8a10aefc7295216c338ba4e1224627a1.svg" alt="unionpay" />
					</div>
				</label>
				<div className="d-flex mb-5 mt-2 updatemethodfield">
				<CardExpiryElement id="ID_EXPIRATION"
						// style={{base: {fontSize: '18px'}}}
						{...createOptions(this.props.fontSize)}
					/>
				<CardCVCElement id="ID_CVC"
						{...createOptions(this.props.fontSize)}
					// style={{base: {fontSize: '18px'}}}
					/>
				</div>
			</div>
			// </label>

			
		);
	}
}

// export default CardSection;
export default injectStripe(CardSection);
