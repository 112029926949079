import React from 'react'
import { useState } from 'react'
import '../../../../App.css'
import { toAbsoluteUrl } from '../../../../_metronic/_helpers'
// import { Link } from 'react-router-dom'
import Utils from '../../../../components/shared/Utils'

export default function ForgotPassword() {
  const utils = new Utils()
  const [loading, setLoading] = useState(false)
  const [userInfo, setUserInfo] = useState('')
  const [message, setMessage] = useState("")

  const handleSubmit = (e) => {
    e.preventDefault()
    setMessage('')
    setLoading(true)
    let url = utils.getServicesUrl() + '/submitPasswordReset?email=' + userInfo
    var myHeaders = {}
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }
    fetch(url, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setLoading(false)
        setMessage(result)
      })
      .catch((error) => {
        // console.log('error', error)
        setLoading(false)
        setMessage("Email not valid")
        // setErrorMessage(error)
        // alert("Email not exist ")
      })
  }

  return (
    <div className="forgotSection row">
      <div className={`forgotDiv1 col-7 col-sm-8 col-md-7 col-lg-7 col-xl-7 ${message !==''? 'forgotDiv1height':''} `}
      >
        <div className="forgotDiv1-head">Forgotten Password?</div>
        <div className="forgotDiv1-desc">
          Enter your email to reset your password
        </div>
      </div>
      <div className="forgotDiv2 col-sm-8 col-md-5 col-lg-5 col-xl-5 p-3">
        <div className="">
          <img
            alt="Logo"
            className="max-h-200px mb-10"
            src={toAbsoluteUrl('/media/logos/jlop.png')}
          />
        </div>
        <div className="h2 forgotDiv2-head" style={{ display: 'none' }}>
          Forgotten Password?
        </div>
        <div className="p forgotDiv2-desc" style={{ display: 'none' }}>
          Enter your email to reset your password
        </div>
        <form
          onSubmit={(e) => handleSubmit(e)}
          className="fogotForm"
          action="/action_page.php"
          style={{ marginTop: '20px' }}
        >
          <div className="form-group text-left">
            <div className="" style={{ maxWidth: '100%' }}>
              <input
                type="email"
                className="form-control"
                id="forGotEmail"
                onChange={(e) => setUserInfo(e.target.value)}
                placeholder="Enter email"
                name="email"
                required
              />
            </div>
          </div>
          <div style={{fontSize:'15px', marginTop:'-20px'}} className={`${message !=='OK' ? "text-danger":"text-success"}`}>{message === 'OK' ? "Email send successfully" : message }</div>
          <div className="form-group d-flex justify-content-around">
            <div className=" ">
              <button
                type="submit"
                className="btn commonButtonClass signInBtn btn-primary font-weight-bold px-7 py-3 my-4  border border-left-0 "
              >
                Submit
                {loading && (
                  <span className="mx-3 spinner spinner-white"></span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
