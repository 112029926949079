import React, { useMemo } from "react";
import { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { Modal } from "react-bootstrap";
import { Button } from "primereact/components/button/Button";
import NinjaProgressSpinner from "../../components/shared/NinjaProgressSpinner";
import Utils from "../../components/shared/Utils";
import { toAbsoluteUrl } from "../_helpers";
import $ from "jquery";
import PaginationGen from "./PaginationGen";
import * as Constants from "../../Constants";
import ApiActivityOfRates from "./ApiActivityOfRates";
import SubscriberSubUser from "./SubscriberSubUser";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ApiActivity from "./ApiActivity";

export default function Subscriber() {
  const utils = new Utils(); // To create instance
  // for confirmation modal
  const [show, setShow] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [apiActOfRateModel, setApiActOfRateModel] = useState(false);
  const [subApiActModel, setSubApiActModel] = useState(false);
  const [subscriberSubUserModel, setSubscriberSubUserModel] = useState(false);
  const [apiActOfRateModelState, setApiActOfRateModelState] = useState({});
  const [activityDetail, setActivityDetail] = useState({});
  const [modalData, setModalData] = useState({});
  const [loader, setLoader] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);
  const [message, setMessage] = useState("");

  // console.log("subActivityModelState", subActivityModelState);

  const handleClose = () => {
    setShow(false);
    setMessage("");
    setErrorMessage(false);
  };
  function handleShow(
    userId,
    firstName,
    lastName,
    email,
    phone,
    planName,
    status,
    createdAt,
    dateCanceled,
    planId,
    priceId,
    stripeCustomerId,
    trialEndDate,
    trialStartDate,
    username
  ) {
    setShow(true);
    setModalData({
      userId,
      firstName,
      lastName,
      email,
      phone,
      planName,
      status,
      createdAt,
      dateCanceled,
      planId,
      priceId,
      stripeCustomerId,
      trialEndDate,
      trialStartDate,
      username,
    });
  }
  //

  const [state, setstate] = useState({}); // State of this component
  const [qury, setqury] = useState("");
  // Api define getUsersWithSubscribtionInfo
  function getUsersWithSubscribtionInfo(page, priceId) {
    // sinner call
    setLoader(true);
    $("#spinnerWrap").css("display", "flex");
    $("#ID_NINJA_SPINNER_IN_PROGRESS").show();
    let url = utils.getServicesUrl() + "/getUsersWithSubscribtionInfo";
    let token = utils.readCookie("token");

    var myHeaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    var data = JSON.stringify({
      userName: "",
      status: "",
      planId: "",
      priceId: priceId,
      fromDate: "",
      toDate: "",
      pageNumber: page,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: data,
      redirect: "follow",
    };
    var statusCode;
    fetch(url, requestOptions)
      .then((response) => {
        statusCode = response.status;
        return response.json();
      })
      .then((result) => {
        if (statusCode === 403) {
          setstate({
            ...state,
            msg403: "You don't have access to this feature.",
          });
        } else {
          setstate(result);
        }

        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        $("#spinnerWrap").css("display", "none");
        setLoader(false);
      })
      .catch((error) => {
        // console.log('error', error)
        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        $("#spinnerWrap").css("display", "none");
        setLoader(false);
        // alert(error);
      });
  }
  // End

  // getUserActivityOfRates
  function getUserActivityOfRates(page, uId) {
    // sinner call
    setLoader(true);
    $("#spinnerWrap").css("display", "flex");
    $("#ID_NINJA_SPINNER_IN_PROGRESS").show();
    let url = utils.getServicesUrl() + "/getUserActivityOfRates";
    let token = utils.readCookie("token");

    var myHeaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    var raw = JSON.stringify({
      fromDate: "",
      toDate: "",
      pageNo: page,
      userId: uId,
      userName: "",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    var statusCode;
    fetch(url, requestOptions)
      .then((response) => {
        statusCode = response.status;
        return response.json();
      })
      .then((result) => {
        if (statusCode === 403) {
          setApiActOfRateModelState({
            ...apiActOfRateModelState,
            msg403: "You don't have access to this feature.",
          });
        } else {
          setApiActOfRateModelState(result);
        }
        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        $("#spinnerWrap").css("display", "none");
        setLoader(false);
        setApiActOfRateModel(true);
      })
      .catch((error) => {
        // console.log('error', error)
        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        $("#spinnerWrap").css("display", "none");
        setLoader(false);
        setApiActOfRateModel(true);
      });
  }
  // End

  // getApiActivities
  function getApiActivities(page, uId) {
    // sinner call
    setLoader(true);
    $("#spinnerWrap").css("display", "flex");
    $("#ID_NINJA_SPINNER_IN_PROGRESS").show();
    let url = utils.getServicesUrl() + "/getApiActivities";
    let token = utils.readCookie("token");

    var myHeaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    var raw = JSON.stringify({
      fromDate: "",
      toDate: "",
      pageNo: page,
      userId: uId,
      userName: "",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    var statusCode;
    fetch(url, requestOptions)
      .then((response) => {
        statusCode = response.status;
        return response.json();
      })
      .then((result) => {
        if (statusCode === 403) {
          setApiActOfRateModelState({
            ...apiActOfRateModelState,
            msg403: "You don't have access to this feature.",
          });
        } else {
          // let newContent = [];
          // result.content.map((obj) => {
          //   obj.createTime = unixDate(obj.createTime);
          //   newContent.push(obj);
          // });
          console.log("apiA1", result);
          // result.content = newContent;
          setApiActOfRateModelState(result);
          // console.log("apiA2",result);
        }
        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        $("#spinnerWrap").css("display", "none");
        setLoader(false);
        setSubApiActModel(true);
      })
      .catch((error) => {
        // console.log('error', error)
        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        $("#spinnerWrap").css("display", "none");
        setLoader(false);
        setSubApiActModel(true);
      });
  }
  // End

  // subscriberSubUser
  function subscriberSubUser(userId) {
    // sinner call
    setLoader(true);
    $("#spinnerWrap").css("display", "flex");
    $("#ID_NINJA_SPINNER_IN_PROGRESS").show();
    let url = utils.getServicesUrl() + "/getAllSubUsersByMasterId";
    let token = utils.readCookie("token");

    var myHeaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    var raw = JSON.stringify({
      user_id: userId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    var statusCode;
    fetch(url, requestOptions)
      .then((response) => {
        statusCode = response.status;
        return response.json();
      })
      .then((result) => {
        if (statusCode === 403) {
          setApiActOfRateModelState({
            ...apiActOfRateModelState,
            msg403: "You don't have access to this feature.",
          });
        } else {
          let newContent = [];
          result.map((obj) => {
            obj.createdAt = unixDate(obj.createdAt);
            obj.updatedAt = unixDate(obj.updatedAt);
            newContent.push(obj);
          });
          result = newContent;
          setApiActOfRateModelState(result);
        }
        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        $("#spinnerWrap").css("display", "none");
        setLoader(false);
        setSubscriberSubUserModel(true);
      })
      .catch((error) => {
        // console.log('error', error)
        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        $("#spinnerWrap").css("display", "none");
        setLoader(false);
        setSubscriberSubUserModel(true);
      });
  }
  // End

  // handle to Cancel Subscription
  const handleCancelSub = () => {
    // sinner call
    setLoader(true);
    let url =
      utils.getServicesUrl() +
      "/cancelSubscriptionByAdmin?&live=" +
      Constants.IS_PRODUCTION;
    // Api Call
    utils.ajaxRequest(
      "PUT",
      url,
      cancelSubscriptionSuccess,
      cancelSubscriptionError,
      { userId: modalData.userId },
      true
    );
  };
  const cancelSubscriptionSuccess = (data, status, response) => {
    // let newData = JSON.parse(data)
    // console.log('cancel success', data.message)
    setMessage(data.message);
    setLoader(false);
  };
  const cancelSubscriptionError = (jqXHR, exception) => {
    // console.log('cancel Sub error', jqXHR.responseJSON.message)
    setErrorMessage(true);
    setMessage(jqXHR.responseJSON.message);
    setLoader(false);
  };

  const filterData = (s) => {
    let q = qury;
    try {
      if (
        s.firstName.toUpperCase().includes(q.toUpperCase()) ||
        s.firstName.toLowerCase().includes(q.toLowerCase()) ||
        s.lastName.toUpperCase().includes(q.toUpperCase()) ||
        s.lastName.toLowerCase().includes(q.toLowerCase()) ||
        s.email.toUpperCase().includes(q.toUpperCase()) ||
        s.email.toLowerCase().includes(q.toLowerCase()) ||
        s.userId.toUpperCase().includes(q.toUpperCase()) ||
        s.userId.toLowerCase().includes(q.toLowerCase()) ||
        s.username.toUpperCase().includes(q.toUpperCase()) ||
        s.username.toLowerCase().includes(q.toLowerCase()) ||
        s.phone.includes(q) ||
        s.subStatus.toUpperCase().includes(q.toUpperCase()) ||
        s.subStatus.toLowerCase().includes(q.toLowerCase())
      ) {
        return s;
      }
    } catch (err) {
      // console.log("err", err.message)
    }
  };

  // Unix Date converter
  const unixDate = (unix) => {
    var a = new Date(unix);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var time = month + " " + date + "," + " " + year;
    return time;
  };
  // End

  // capitalizeFirstLetter
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  // End

  const redirectUrl = () => {
    window.location.href = "/admin-dashboard";
  };
  useEffect(() => {
    // Api CALL getUsersWithSubscribtionInfo
    getUsersWithSubscribtionInfo(0, "");
    // End
    window.addEventListener("load", redirectUrl, false);
  }, []);

  return (
    <>
      {/* Search Box */}
      <div className="d-flex justify-content-end">
        <form className="navbar-form navbar-right" role="search">
          <div className="input-group align-items-baseline">
            <input
              type="text"
              id="searchBox"
              onChange={(e) => setqury(e.target.value)}
              className="form-control"
              placeholder="Search"
            />
            <i
              style={{ marginLeft: "-29px", zIndex: "1" }}
              className="flaticon-search-magnifier-interface-symbol"
            ></i>
          </div>
        </form>
      </div>
      {/* End */}
      <h1 style={{ marginBottom: "30px" }}>Subscribers</h1>
      <div id="spinnerWrap">
        <NinjaProgressSpinner maxWidth="100px" maxHeight="100px" />
      </div>
      <div className={`table-responsive ${loader ? "isLoaderGen" : ""}`}>
        <table
          className="table table-head-custom table-vertical-center"
          id="kt_advance_table_widget_1"
        >
          <thead>
            <tr className="text-left">
              <th className="pr-0" style={{ minWidth: "50px" }}>
                Names
              </th>
              <th style={{ minWidth: "50px" }}>Email</th>
              <th style={{ minWidth: "50px" }}>Phone No</th>
              <th style={{ minWidth: "30px" }}>Subscription PLan</th>
              <th style={{ minWidth: "30px" }}>Subscription status</th>
              <th style={{ minWidth: "30px" }}>Date of Subscription</th>
              <th style={{ minWidth: "100px" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {state.content
              ? state.content.filter(filterData).map((val, i) => (
                  <tr key={i}>
                    <td>
                      <OverlayTrigger
                        overlay={
                          <Tooltip
                            style={{
                              background: "#C0C0C0",
                              padding: "5px 5px",
                              borderRadius: "5px",
                            }}
                            id="tooltip"
                          >
                            Subscriber view
                          </Tooltip>
                        }
                      >
                        <span
                          className="btn text-primary font-weight-bolder font-size-lg"
                          id="ID_UPDATE_SUBSCRIPTION"
                          onClick={() => {
                            setCancelModal(false);
                            handleShow(
                              val.userId,
                              val.firstName,
                              val.lastName,
                              val.email,
                              val.phone,
                              val.planName,
                              val.subStatus,
                              val.createdAt,
                              val.dateCanceled,
                              val.planId,
                              val.priceId,
                              val.stripeCustomerId,
                              val.trialEndDate,
                              val.trialStartDate,
                              val.username
                            );
                          }}
                        >
                          {val.firstName} {val.lastName}
                        </span>
                      </OverlayTrigger>
                    </td>
                    <td>
                      <span className="text-dark-75 mb-1 font-size-lg">
                        {val.email}
                      </span>
                    </td>
                    <td>
                      <span className="">{val.phone}</span>
                    </td>
                    <td style={{ minWidth: "50px" }}>
                      <span className="">
                        {val.planName == null || val.planName == ""
                          ? "null"
                          : val.planName}
                      </span>
                    </td>
                    <td style={{ minWidth: "50px" }}>
                      <span
                        style={{
                          fontWeight: "600",
                          padding: "2px 4px 4px 4px",
                          borderRadius: "0px",
                        }}
                        className={`badge   ${
                          val.subStatus.toLowerCase() == "active"
                            ? "badge-success"
                            : ""
                        } ${
                          val.subStatus.toLowerCase() == "suspend"
                            ? "badge-warning"
                            : ""
                        } ${
                          val.subStatus.toLowerCase() == "cancel"
                            ? "badge-danger"
                            : ""
                        }  `}
                      >
                        {val.subStatus && capitalizeFirstLetter(val.subStatus)}
                      </span>
                    </td>
                    <td>
                      <span className="">{unixDate(val.createdAt)}</span>
                    </td>
                    <td className="pr-0">
                      <OverlayTrigger
                        overlay={
                          <Tooltip
                            style={{
                              background: "#C0C0C0",
                              padding: "5px 5px",
                              borderRadius: "5px",
                            }}
                            id="tooltip"
                          >
                            Subscription view
                          </Tooltip>
                        }
                      >
                        <span
                          className="btn btn-icon btn-light btn-sm mx-1 my-1"
                          id="ID_UPDATE_SUBSCRIPTION"
                          onClick={() => {
                            setCancelModal(true);
                            handleShow(
                              val.userId,
                              val.firstName,
                              val.lastName,
                              val.email,
                              val.phone,
                              val.planName,
                              val.subStatus,
                              val.createdAt
                            );
                          }}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            <i className="ki ki-info text-primary icon-md"></i>
                          </span>
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip
                            style={{
                              background: "#C0C0C0",
                              padding: "5px 5px",
                              borderRadius: "5px",
                            }}
                            id="tooltip"
                          >
                            API rates activity
                          </Tooltip>
                        }
                      >
                        <span
                          className="btn btn-icon btn-light btn-sm mx-1 my-1"
                          id="ID_UPDATE_SUBSCRIPTION"
                          onClick={() => {
                            setActivityDetail({
                              userId: val.userId,
                              userName: val.username,
                              email: val.email,
                            });
                            getUserActivityOfRates(0, val.userId);
                          }}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            <i className="flaticon2-graph-1 text-primary"></i>
                          </span>
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip
                            style={{
                              background: "#C0C0C0",
                              padding: "5px 5px",
                              borderRadius: "5px",
                            }}
                            id="tooltip"
                          >
                            API activity
                          </Tooltip>
                        }
                      >
                        <span
                          className="btn btn-icon btn-light btn-sm mx-1 my-1"
                          id="ID_UPDATE_SUBSCRIPTION"
                          onClick={() => {
                            setActivityDetail({
                              userId: val.userId,
                              userName: val.username,
                              email: val.email,
                            });
                            getApiActivities(0, val.userId);
                          }}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            <i className="fas fa-tasks text-primary"></i>
                          </span>
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip
                            style={{
                              background: "#C0C0C0",
                              padding: "5px 5px",
                              borderRadius: "5px",
                            }}
                            id="tooltip"
                          >
                            List of sub users
                          </Tooltip>
                        }
                      >
                        <span
                          className="btn btn-icon btn-light btn-sm mx-1 my-1"
                          id="ID_UPDATE_SUBSCRIPTION"
                          onClick={() => {
                            setActivityDetail({
                              userId: val.userId,
                              userName: val.username,
                              email: val.email,
                            });
                            subscriberSubUser(val.userId);
                          }}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            <i className="fa fa-users text-primary"></i>
                          </span>
                        </span>
                      </OverlayTrigger>
                    </td>
                  </tr>
                ))
              : ""}
          </tbody>
        </table>
        <div className="text-danger text-center">{state.msg403}</div>
      </div>
      {state.pageable && (
        <PaginationGen
          prceId=""
          page={state.pageable.pageNumber}
          totalPage={state.totalPages}
          getUsersWithSubscribtionInfo={getUsersWithSubscribtionInfo}
        />
      )}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Subscriber Details </Modal.Title>
          <span id="closeIcon" onClick={handleClose}>
            ✕
          </span>
        </Modal.Header>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "10px",
          }}
        >
          {loader ? (
            <span
              style={{ marginRight: "70px" }}
              className="spinner spinner-danger"
            ></span>
          ) : (
            <span
              style={{ marginRight: "70px" }}
              className={`${errorMessage ? "text-danger" : "text-success"}`}
            >
              {message}
            </span>
          )}
          {cancelModal && modalData.status != "cancel" && (
            <Button
              variant="primary"
              style={{ marginBottom: "-10px", marginRight: "12px" }}
              className="commonButtonClass"
              onClick={handleCancelSub}
            >
              Cancel Subscription
            </Button>
          )}
        </div>

        <Modal.Body>
          <table>
            <thead></thead>
            <tbody>
              {cancelModal ? (
                ""
              ) : (
                <tr>
                  <th scope="row">User Id:</th>
                  <td>{modalData.userId}</td>
                  <td></td>
                </tr>
              )}
              {cancelModal ? (
                ""
              ) : (
                <tr>
                  <th scope="row">User Name:</th>
                  <td>{modalData.username}</td>
                  <td></td>
                </tr>
              )}
              <tr>
                <th scope="row">Subscriber Name:</th>
                <td>{modalData.firstName + " " + modalData.lastName}</td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">Email:</th>
                <td>{modalData.email}</td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">Phone No:</th>
                <td>{modalData.phone}</td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">Subscription PLan:</th>
                <td>{modalData.planName}</td>
                <td></td>
              </tr>
              {cancelModal ? (
                ""
              ) : (
                <tr>
                  <th scope="row">PLan Id:</th>
                  <td>{modalData.planId}</td>
                  <td></td>
                </tr>
              )}
              {cancelModal ? (
                ""
              ) : (
                <tr>
                  <th scope="row">Price Id:</th>
                  <td>{modalData.priceId}</td>
                  <td></td>
                </tr>
              )}
              {cancelModal ? (
                ""
              ) : (
                <tr>
                  <th scope="row">Stripe Customer Id:</th>
                  <td>{modalData.stripeCustomerId}</td>
                  <td></td>
                </tr>
              )}
              <tr>
                <th scope="row">Subscription status:</th>
                <td>
                  <span
                    style={{
                      fontWeight: "600",
                      padding: "2px 4px 4px 4px",
                      borderRadius: "0px",
                    }}
                    className={`badge  ${
                      modalData.status &&
                      modalData.status.toLowerCase() == "active"
                        ? "badge-success"
                        : ""
                    } ${
                      modalData.status &&
                      modalData.status.toLowerCase() == "suspend"
                        ? "badge-warning"
                        : ""
                    } ${
                      modalData.status &&
                      modalData.status.toLowerCase() == "cancel"
                        ? "badge-danger"
                        : ""
                    }  `}
                  >
                    {modalData.status &&
                      capitalizeFirstLetter(modalData.status)}
                  </span>
                </td>
                <td></td>
              </tr>
              <tr>
                <th scope="row"> Date of Subscription:</th>
                <td>{unixDate(modalData.createdAt)}</td>
                <td></td>
              </tr>
              {cancelModal ? (
                ""
              ) : (
                <tr>
                  <th scope="row"> Trial Start Date:</th>
                  <td>{unixDate(modalData.trialStartDate)}</td>
                  <td></td>
                </tr>
              )}
              {cancelModal ? (
                ""
              ) : (
                <tr>
                  <th scope="row"> Trial End Date:</th>
                  <td>{unixDate(modalData.trialEndDate)}</td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
      {/* subActivityOfRate Model */}
      {apiActOfRateModel && (
        <ApiActivityOfRates
          apiActOfRateModel={apiActOfRateModel}
          setApiActOfRateModel={setApiActOfRateModel}
          apiActOfRateModelState={apiActOfRateModelState}
          getUserActivityOfRates={getUserActivityOfRates}
          activityDetail={activityDetail}
        />
      )}
      {/* End */}
      {/* getApiActivities Model */}
      {subApiActModel && (
        <ApiActivity
          subApiActModel={subApiActModel}
          setSubApiActModel={setSubApiActModel}
          apiActOfRateModelState={apiActOfRateModelState}
          getApiActivities={getApiActivities}
          activityDetail={activityDetail}
        />
      )}
      {/* End */}
      {/* getApiActivities Model */}
      {subscriberSubUserModel && (
        <SubscriberSubUser
          subscriberSubUserModel={subscriberSubUserModel}
          setSubscriberSubUserModel={setSubscriberSubUserModel}
          subscriberSubUserModelState={apiActOfRateModelState}
          subscriberDetail={activityDetail}
        />
      )}
      {/* End */}
    </>
  );
}
