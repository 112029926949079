import React from 'react'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'

export const SaveFileAsXlsx = ({ csvData, fileName }) => {

  const unixDate = (unix) => {
    var a = new Date(unix)
    var months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]
  var hours = a.getHours();
  var minutes = "0" + a.getMinutes();
  var seconds = "0" + a.getSeconds();
  var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    var year = a.getFullYear()
    var month = months[a.getMonth()]
    var date = a.getDate()
    var time = month + ' ' + date + ',' + ' ' + year + ' ' + formattedTime;
    return time
  }
  
  const newCsvData=[]

  if (csvData) {
    csvData.forEach(obj => {
      obj.createdAt = unixDate(obj.createdAt);
      obj.trialEndDate = unixDate(obj.trialEndDate);
      obj.trialStartDate = unixDate(obj.trialStartDate);
      if (obj.dateCanceled) {
        obj.dateCanceled = unixDate(obj.dateCanceled);
      }
      newCsvData.push(obj)
    });
  }
  
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'

  const fileExtension = '.xlsx'

  const exportToCSV = (newCsvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData)

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })

    const data = new Blob([excelBuffer], { type: fileType })

    FileSaver.saveAs(data, fileName + fileExtension)
  }

  return (
    <button
      onClick={(e) => exportToCSV(newCsvData, fileName)}
      className="btn btn-light-success btn-lg"
      style={{
        float: 'right',
        fontWeight: 'bold',
        margin: '10px',
        position: 'relative',
      }}
    >
      Download xlsx
    </button>
  )
}
