import React, { useMemo } from "react";
import { useState } from "react";
import $ from "jquery";
import Utils from "../../components/shared/Utils";
import { useEffect } from "react";
import NinjaProgressSpinner from "../../components/shared/NinjaProgressSpinner";
import * as jsPDF from "jspdf";
import "jspdf-autotable";
import { SaveFileAsXlsx } from "./saveFileAsXlsx";
import PaginationGen from "./PaginationGen";

export default function Reports() {
  const utils = new Utils(); // To create instance
  const [state, setstate] = useState({}); // State of this component
  const [loader, setLoader] = useState(false);
  const [prceId, setPrceId] = useState("");

  const [getValSubInfo, setGetValSubInfo] = useState({
    userName: "",
    planId: "",
    fromDate: "",
    toDate: "",
  });

  // Api define
  function getUsersWithSubscribtionInfo(page, priceId) {
    // sinner call
    $("#spinnerWrap").css("display", "flex");
    setLoader(true);
    $("#ID_NINJA_SPINNER_IN_PROGRESS").show();
    let token = utils.readCookie("token");
    let urlSubscription =
      utils.getServicesUrl() + "/getUsersWithSubscribtionInfo";
    var myHeaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const dt = {
      ...getValSubInfo,
      pageNumber: page,
      priceId: priceId,
      status: "",
    };
    var data = JSON.stringify(dt);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: data,
      redirect: "follow",
    };

    fetch(urlSubscription, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setstate(result);
        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        setLoader(false);
        $("#spinnerWrap").css("display", "none");
      })
      .catch((error) => {
        // console.log('error', error)
        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        setLoader(false);
        $("#spinnerWrap").css("display", "none");
        // alert(error);
      });
  }
  // End

  const handleSubmit = (event) => {
    event.preventDefault();
    getUsersWithSubscribtionInfo(0, prceId);
  };

  const handleChange = (e) => {
    setGetValSubInfo({
      ...getValSubInfo,
      [e.target.name]: e.target.value,
    });
  };

  const filName = "Jackalope_Report_" + new Date().getTime();
  // Generate/download function
  const generatePdf = () => {
    let filename = filName + ".pdf";
    var pdf = new jsPDF("p", "pt");
    var image = utils.getPdfNinjaImage();
    pdf.setFontType("normal");
    var col = [
      "Sr#",
      "SUBSCRIBER NAMES",
      "NO# OF SUBSCRIPTIONS",
      "SUBSCRIPTION PLAN",
      "SUBSCRIPTION STATUS",
      "DATE OF SUBSCRIPTION",
    ];
    var rows = [];
    try {
      state.content.forEach((element, i) => {
        var temp = [
          i + 1,
          element.firstName + " " + element.lastName,
          element.numberOfSubscription ? element.numberOfSubscription : "null",
          element.planName == null ? "null" : element.planName,
          element.subStatus,
          ,
          unixDate(element.createdAt),
        ];
        rows.push(temp);
      });
    } catch (error) {
      // console.log('error', error)
    }
    pdf.autoTable(col, rows, {
      theme: "striped",
      margin: { top: 120 },
      addPageContent: (data) => {
        pdf.addImage(image, "PNG", 35, 45);
        pdf.setFontSize(26);
        pdf.text(utils.getNinjaterInfo().name, 119, 70);
        pdf.setFontSize(10);
        pdf.setTextColor(128, 128, 128);
        pdf.text(utils.getNinjaterInfo().email, 119, 85);
        pdf.text(utils.getNinjaterInfo().twitter, 119, 100);
      },
      styles: {
        overflow: "linebreak",
      },
    });
    pdf.save(filename);
  };

  const unixDate = (unix) => {
    var a = new Date(unix);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var time = month + " " + date + "," + " " + year;
    return time;
  };

  const redirectUrl = () => {
    window.location.href = "/admin-dashboard";
  };

  // capitalizeFirstLetter
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  // End

  useEffect(() => {
    // Api call
    getUsersWithSubscribtionInfo(0, "");
    // End
    window.addEventListener("load", redirectUrl, false);
  }, []);

  return (
    <>
      <h1 style={{ marginBottom: "30px" }}>Reports</h1>
      <div id="spinnerWrap">
        <NinjaProgressSpinner maxWidth="100px" maxHeight="100px" />
      </div>

      <div>
        <form
          className={`row ${loader ? "isLoaderGen" : ""}`}
          onSubmit={(event) => handleSubmit(event)}
        >
          <div className="form-group col-4">
            <label for="usernameIn">Name</label>
            <input
              type="text"
              onChange={handleChange}
              className="form-control"
              name="userName"
              id="usernameIn"
              placeholder="Enter user name"
            />
          </div>
          <div className="form-group col-4">
            <label for="planIdIn">Plan Id</label>
            <input
              type="text"
              onChange={handleChange}
              className="form-control"
              name="planId"
              id="planIdIn"
              placeholder="Enter plan id"
            />
          </div>
          <div className="form-group col-4">
            <label for="priceIdIn">Price Id</label>
            <input
              type="text"
              onChange={(e) => setPrceId(e.target.value)}
              className="form-control"
              name="priceId"
              id="priceIdIn"
              placeholder="Enter price id"
            />
          </div>
          <div className="form-group col-4">
            <label for="fromDateIn">From date</label>
            <input
              type="date"
              onChange={handleChange}
              className="form-control"
              name="fromDate"
              id="fromDateIn"
            />
          </div>
          <div className="form-group col-4">
            <label for="toDateIn">To date</label>
            <input
              type="date"
              onChange={handleChange}
              className="form-control"
              name="toDate"
              id="toDateIn"
            />
          </div>
          <div className="text-center" style={{ marginTop: "30px" }}>
            <button type="submit" className="btn btn-light-success text-center">
              <span style={{ fontWeight: "800", fontSize: "15px" }}>
                Search
              </span>
              <i className="ml-4 fa fa-search"></i>
            </button>
          </div>
        </form>
      </div>

      <button
        type="button"
        onClick={() => {
          generatePdf();
        }}
        className="btn btn-light-success btn-lg"
        style={{
          float: "right",
          fontWeight: "bold",
          margin: "10px",
          position: "relative",
        }}
      >
        Download pdf
      </button>
      <SaveFileAsXlsx csvData={state.content} fileName={filName} />
      <div class="table-responsive">
        <table
          className="table table-head-custom  table-vertical-center"
          id="kt_advance_table_widget_1"
        >
          <thead>
            <tr>
              <th scope="col">Subscriber Names</th>
              <th scope="col">Email</th>
              <th scope="col">Subscription PLan</th>
              <th scope="col">Subscription status</th>
              <th scope="col">Date of Subscription</th>
            </tr>
          </thead>
          <tbody>
            {state.content
              ? state.content.map((val, ind) => (
                  <tr key={ind}>
                    <td>{val.firstName + " " + val.lastName}</td>
                    <td>
                      <span>{val.email ? val.email : "null"}</span>
                    </td>
                    <td>
                      <span>
                        {val.planName == null ? "null" : val.planName}
                      </span>
                    </td>
                    <td>
                      <span
                        style={{
                          fontWeight: "600",
                          padding: "2px 4px 4px 4px",
                          borderRadius: "0px",
                        }}
                        className={`badge   ${
                          val.subStatus.toLowerCase() == "active"
                            ? "badge-success"
                            : ""
                        } ${
                          val.subStatus.toLowerCase() == "suspend"
                            ? "badge-warning"
                            : ""
                        } ${
                          val.subStatus.toLowerCase() == "cancel"
                            ? "badge-danger"
                            : ""
                        }  `}
                      >
                        {val.subStatus && capitalizeFirstLetter(val.subStatus)}
                      </span>
                    </td>
                    <td>
                      <span>{unixDate(val.createdAt)}</span>
                    </td>
                  </tr>
                ))
              : ""}
          </tbody>
        </table>
      </div>
      {state.pageable && (
        <PaginationGen
          prceId={prceId}
          page={state.pageable.pageNumber}
          totalPage={state.totalPages}
          getUsersWithSubscribtionInfo={getUsersWithSubscribtionInfo}
        />
      )}
    </>
  );
}
