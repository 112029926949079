import React from "react";
import Utils from "../../components/shared/Utils";
import PaginationGen from "./PaginationGen";
import { Modal } from "react-bootstrap";
import { Button } from "primereact/components/button/Button";
import { useState } from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import $ from "jquery";
import NinjaProgressSpinner from "../../components/shared/NinjaProgressSpinner";

export default function ApiActivity(props) {
  const utils = new Utils();
  const [state, setState] = useState({ ...props.apiActOfRateModelState }); // State of this component
  const [qury, setqury] = useState("");
  const [userName, setUserName] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [loader, setLoader] = useState(false);
  // console.log("state", state);

  // getApiActivities
  function getApiActivities(pg, uId, uName, fDate, tDate) {
    // sinner call
    setLoader(true);
    $("#spinnerWrap").css("display", "flex");
    $("#ID_NINJA_SPINNER_IN_PROGRESS").show();
    let url = utils.getServicesUrl() + "/getApiActivities";
    let token = utils.readCookie("token");

    var myHeaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    var raw = JSON.stringify({
      fromDate: fDate,
      toDate: tDate,
      pageNo: pg,
      userId: uId,
      userName: uName,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    var statusCode;
    fetch(url, requestOptions)
      .then((response) => {
        statusCode = response.status;
        return response.json();
      })
      .then((result) => {
        if (statusCode === 403) {
          setState({
            ...state,
            msg403: "You don't have access to this feature.",
          });
        } else {
          setState(result);
        }
        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        $("#spinnerWrap").css("display", "none");
        setLoader(false);
      })
      .catch((error) => {
        // console.log('error', error)
        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        $("#spinnerWrap").css("display", "none");
        setLoader(false);
      });
  }
  // End

  // Data into Arr Form
  const newCsvData = [];
  if (state.content) {
    state.content.forEach((obj) => {
      newCsvData.push(obj);
    });
  }

  // End
  console.log("newCsvData", newCsvData);

  const handleClose = () => {
    props.setSubApiActModel(false);
  };

  // Filter Data
  const filterData = (s) => {
    let q = qury;
    try {
      if (
        s.firstName.toUpperCase().includes(q.toUpperCase()) ||
        s.firstName.toLowerCase().includes(q.toLowerCase()) ||
        s.lastName.toUpperCase().includes(q.toUpperCase()) ||
        s.lastName.toLowerCase().includes(q.toLowerCase()) ||
        s.startDate.toString().includes(q) ||
        s.zipCode.toString().includes(q) ||
        s.classCode.toString().includes(q) ||
        s.exModFactor.toString().includes(q) ||
        s.ageOfEmployee.toString().includes(q) ||
        s.numberOfEmployees.toString().includes(q) ||
        s.numberOfClaims.toString().includes(q) ||
        s.payroll.toString().includes(q) ||
        s.yearsInBusiness.toString().includes(q)
      ) {
        return s;
      }
    } catch (err) {
      // console.log("err", err.message)
    }
  };
  // End

  // Generate Excel
  const filName =
    "Jackalope_User_" +
    props.activityDetail.userName +
    "_Activities_" +
    new Date().getTime();
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(newCsvData);

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, filName + fileExtension);
  };
  // End

  return (
    <div>
      <Modal
        show={props.subApiActModel && props.subApiActModel}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        style={{ maxWidth: "fitContent" }}
      >
        {state.msg403 ? (
          <div
            style={{ padding: "15px 30px" }}
            className="text-success text-danger"
          >
            {state.msg403}
          </div>
        ) : (
          <>
            <Modal.Header>
              <Modal.Title>API Activity Log </Modal.Title>
              <span id="closeIcon" onClick={handleClose}>
                ✕
              </span>
            </Modal.Header>
            <Modal.Body>
              {state.totalElements != 0 && (
                <div className="d-flex flex-row-reverse align-items-baseline">
                  {/* Search Box */}
                  <form
                    style={{ marginRight: "20px" }}
                    className="navbar-form"
                    role="search"
                  >
                    <div className="input-group align-items-baseline">
                      <input
                        type="text"
                        id="searchBox"
                        onChange={(e) => setqury(e.target.value)}
                        className="form-control"
                        placeholder="Search"
                      />
                      <i
                        style={{ marginLeft: "-29px", zIndex: "1" }}
                        className="flaticon-search-magnifier-interface-symbol"
                      ></i>
                    </div>
                  </form>
                  {/* End */}
                </div>
              )}
              <div
                className="row"
                style={{ maxWidth: "inherit", margin: "0px" }}
              >
                <table className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                  <thead></thead>
                  <tbody style={{ border: "none" }}>
                    <tr>
                      <th style={{ width: "120px" }} scope="row">
                        User Name:{" "}
                      </th>
                      <td>{props.activityDetail.userName}</td>
                    </tr>
                    <tr style={{ backgroundColor: "white" }}>
                      <th style={{ width: "120px" }} scope="row">
                        Email:{" "}
                      </th>
                      <td>{props.activityDetail.email}</td>
                    </tr>
                    <tr>
                      <th style={{ width: "120px" }} scope="row">
                        User Id:{" "}
                      </th>
                      <td>{props.activityDetail.userId}</td>
                      <td></td>
                    </tr>
                    <tr style={{ backgroundColor: "white" }}>
                      <th style={{ width: "120px" }} scope="row">
                        Total Activities:
                      </th>
                      <td>
                        <h5 style={{ margin: "0px" }}>
                          <span
                            style={{
                              fontWeight: "600",
                              borderRadius: "0px",
                              padding: "2px 0px",
                            }}
                            className="badge badge-secondary"
                          >
                            {state.totalElements}
                          </span>
                        </h5>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    padding: "0",
                  }}
                  className="col-xl-7 col-lg-7 col-md-7 col-sm-12"
                >
                  {props.subApiActModel && (
                    <div className="row align-items-baseline">
                      <input
                        style={{
                          border: "none",
                          height: "32px",
                          marginRight: "22px",
                          backgroundColor: "ghostwhite",
                          color: "lightslategray",
                        }}
                        className="col-xl-4 col-lg-4 col-md-4 col-sm-12"
                        onChange={(e) => setUserName(e.target.value)}
                        type="text"
                        id="fromDate"
                        placeholder="user name"
                      />
                      <input
                        style={{
                          border: "none",
                          height: "32px",
                          width: "94%",
                          backgroundColor: "ghostwhite",
                        }}
                        className="col-xl-3 col-lg-3 col-md-3 col-sm-11"
                        onChange={(e) => setfromDate(e.target.value)}
                        type="date"
                        id="fromDate"
                      />
                      <span className="col-1 text-center">To</span>
                      <input
                        style={{
                          border: "none",
                          height: "32px",
                          width: "94%",
                          backgroundColor: "ghostwhite",
                        }}
                        className="col-xl-3 col-lg-3 col-md-3 col-sm-11"
                        onChange={(e) => setToDate(e.target.value)}
                        type="date"
                        id="toDate"
                      />
                    </div>
                  )}
                  <br />
                  <div className="mb-5" style={{ textAlign: "end" }}>
                    {props.subApiActModel && (
                      <button
                        style={{
                          marginRight: "12px",
                        }}
                        id="btnActExprtXlsx"
                        className="btn btn-light-success text-center"
                        disabled={fromDate || toDate || userName ? false : true}
                        onClick={() =>
                          getApiActivities(
                            0,
                            props.activityDetail.userId,
                            userName,
                            fromDate,
                            toDate
                          )
                        }
                      >
                        {loader && (
                          <span
                            style={{ marginRight: "26px" }}
                            className="spinner spinner-white"
                          ></span>
                        )}
                        <span>Search</span>
                      </button>
                    )}

                    <button
                      style={{ height: "38px" }}
                      id="btnActExprtXlsx"
                      onClick={(e) => exportToCSV()}
                      className="btn btn-light-success text-center"
                    >
                      Export xlsx
                    </button>
                  </div>
                </div>
              </div>

              <div className="subActTabRes">
                <table>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ color: "#8b8d94" }}>
                        Act#
                      </th>
                      <th className="text-center" style={{ color: "#8b8d94" }}>
                        Name
                      </th>

                      <th className="text-center" style={{ color: "#8b8d94" }}>
                        Start Date
                      </th>
                      <th className="text-center" style={{ color: "#8b8d94" }}>
                        Zip Code
                      </th>
                      <th className="text-center" style={{ color: "#8b8d94" }}>
                        Class Code
                      </th>
                      <th className="text-center" style={{ color: "#8b8d94" }}>
                        exMod Factor
                      </th>
                      <th className="text-center" style={{ color: "#8b8d94" }}>
                        Age of Employee
                      </th>
                      <th className="text-center" style={{ color: "#8b8d94" }}>
                        Number of Employees
                      </th>
                      <th className="text-center" style={{ color: "#8b8d94" }}>
                        Number of Claims
                      </th>
                      <th className="text-center" style={{ color: "#8b8d94" }}>
                        payroll
                      </th>
                      <th className="text-center" style={{ color: "#8b8d94" }}>
                        Years In Business
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.totalElements === 0 && (
                      <tr>
                        <th>No Data found</th>
                      </tr>
                    )}
                    {newCsvData &&
                      newCsvData.filter(filterData).map((val, ind) => (
                        <tr key={ind}>
                          <th className="text-center" scope="row">
                            {ind + 1}
                          </th>
                          <td className="text-center">
                            {val.firstName + " " + val.lastName}
                          </td>
                          <td className="text-center">{val.startDate}</td>
                          <td className="text-center">{val.zipCode}</td>
                          <td className="text-center">{val.classCode}</td>
                          <td className="text-center">{val.exModFactor}</td>
                          <td className="text-center">{val.ageOfEmployee}</td>
                          <td className="text-center">
                            {val.numberOfEmployees}
                          </td>
                          <td className="text-center">{val.numberOfClaims}</td>
                          <td className="text-center">
                            {val.payroll && val.payroll}
                          </td>
                          <td className="text-center">{val.yearsInBusiness}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {state.pageable && (
                <PaginationGen
                  page={state.pageable.pageNumber}
                  totalPage={state.totalPages}
                  getUsersWithSubscribtionInfo={props.getApiActivities}
                  subActivityOfRates={
                    props.subApiActModel && props.subApiActModel
                  }
                  getUserActivityOfRates={getApiActivities}
                  userId={props.activityDetail.userId}
                  userName={userName}
                  fromDate={fromDate ? fromDate : ""}
                  toDate={toDate ? toDate : ""}
                />
              )}
            </Modal.Body>
          </>
        )}
      </Modal>
    </div>
  );
}
