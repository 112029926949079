import React, { useEffect } from "react";
import Utils from "./components/shared/Utils";
import $ from "jquery";
import NinjaProgressSpinner from "./components/shared/NinjaProgressSpinner";
import { useParams } from "react-router-dom";

export default function WebHook() {
  const utils = new Utils(); // To create instance
  let { string } = useParams();

  // webhook data send to backend
  function dataSendToBackend() {
    // sinner call
    $("#spinnerWrap").css("display", "flex");
    $("#ID_NINJA_SPINNER_IN_PROGRESS").show();
    let url = utils.getServicesUrl();

    var myHeaders = {
      "Content-Type": "application/json",
    };

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("backend send response", result);
        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        $("#spinnerWrap").css("display", "none");
      })
      .catch((error) => {
        console.log("backend send error", error);
        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        $("#spinnerWrap").css("display", "none");
      });
  }
  // End

  useEffect(() => {
    // Api CALL getUsersWithSubscribtionInfo
    dataSendToBackend();
  }, []);

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "99.9vh" }}
    >
      <div id="spinnerWrap">
        <NinjaProgressSpinner maxWidth="100px" maxHeight="100px" />
      </div>
      <h1>{string}</h1>
    </div>
  );
}
