import {request} from "https";
import JwtDecode from "jwt-decode";
import Utils from "../../components/shared/Utils";
// const utils = new Utils();

export const apiMiddleware = (store) => (next) => (action) => {
  // let token = utils.readCookie("token");
  action.headers = {
    "Content-Type": "application/json",
    // "Authorization": `Bearer ${token}`
  };
  next(action);
};
