import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "babel-polyfill";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import NinjaRaterApp from "./NinjaRaterApp";
import NinjaRaterPublic from "./NinjaRaterPublic";
import { HashRouter } from "react-router-dom";
import registerServiceWorker from "./registerServiceWorker";
import { default as Store } from "./state/store";
import { Provider } from "react-redux";
import * as _redux from "./redux";
import axios from "axios";
import store, { persistor } from "./redux/store";
import App from "./app/App";
import "./index.scss"; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
// Datepicker
import "react-datepicker/dist/react-datepicker.css";
import {
  MetronicLayoutProvider,
  MetronicSplashScreenProvider,
  MetronicSubheaderProvider,
  MaterialThemeProvider,
  Layout,
} from "./_metronic/layout";
import { MetronicI18nProvider } from "./_metronic/i18n";
import { useHistory } from "react-router-dom";
import { DashboardPage } from "./app/pages/DashboardPage";
import $ from "jquery";
import Subscriber from "./_metronic/_partials/Subscriber";
import Subscription from "./_metronic/_partials/Subscription";
import Reports from "./_metronic/_partials/Reports";
import Carriers from "./_metronic/_partials/Carriers";
import UserManagement from "./_metronic/_partials/UserManagement";
import UserPermissions from "./_metronic/_partials/UserPermissions";
import Settings from "./_metronic/_partials/Settings";

const { PUBLIC_URL } = process.env;

_redux.setupAxios(axios, store);

const AdminIndex = (props) => {
  const history = useHistory();
  useEffect(() => {
    $("#footer").remove();
  }, []);

  return (
    <MetronicI18nProvider>
      <MetronicLayoutProvider>
        <MetronicSubheaderProvider>
          <MetronicSplashScreenProvider>
            <MaterialThemeProvider>
              <Layout>
                {history.location.state ? (
                  history.location.state.value == "subscriber" ? (
                    <Subscriber />
                  ) : history.location.state.value == "user-management" ? (
                    <UserManagement />
                  ) : history.location.state.value == "user-edit" ? (
                    <UserPermissions />
                  ) : history.location.state.value == "subscription" ? (
                    <Subscription />
                  ) : history.location.state.value == "reports" ? (
                    <Reports />
                  ) : history.location.state.value == "carriers" ? (
                    <Carriers />
                  ) : history.location.state.value == "settings" ? (
                    <Settings />
                  ) : (
                    <DashboardPage />
                  )
                ) : (
                  <DashboardPage />
                )}
              </Layout>
            </MaterialThemeProvider>
          </MetronicSplashScreenProvider>
        </MetronicSubheaderProvider>
      </MetronicLayoutProvider>
    </MetronicI18nProvider>
  );
};

export default AdminIndex;
