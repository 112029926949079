import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
// import Typography from '@material-ui/core/Typography'
import Pagination from '@material-ui/lab/Pagination'
// import $ from 'jquery'
// import NinjaProgressSpinner from '../../components/shared/NinjaProgressSpinner'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}))

export default function PaginationGen(props) {
  const classes = useStyles()
  // console.log("props of pagination",props);

  const handleChange = (event, value) => {
    if (props.subActivityOfRates) {
      props.getUserActivityOfRates(value-1,props.userId, props.userName, props.fromDate, props.toDate)
    } else {
      props.getUsersWithSubscribtionInfo(value-1,props.prceId)
    }
  }

  return (
    <div className={classes.root} style={{display:'flex', justifyContent:'flex-end'}}>
      <Pagination
        count={props.totalPage}
        page={props.page+1}
        onChange={handleChange}
        size="large"
        variant="outlined"
        color="secondary"
        showFirstButton
        showLastButton
      />
    </div>
  )
}
