import React, { useState } from "react";
import Utils from "../../components/shared/Utils";
import $ from "jquery";
import NinjaProgressSpinner from "../../components/shared/NinjaProgressSpinner";
import { useEffect } from "react";

export default function UserPermissions(props) {
  const utils = new Utils(); // To create instance
  // for confirmation modal
  const [loader, setLoader] = useState(false);
  const [userToManage, setUserToManage] = useState({});
  const [rolesToManage, setRolesToManage] = useState([]);
  const [updateUsersuccess, setUpdateUserSuccess] = useState({});
  const [updateUserError, setUpdateUserError] = useState(false);
  const [updateRolesSuccess, setUpdateRolesSuccess] = useState({});
  const [getRolesSuccess, setGetRolesSuccess] = useState([{}]);
  const [userRoleIds, setUserRoleIds] = useState([]);
  const [getUserInfo, setGetUserInfo] = useState({});
  const [updateRolesError, setUpdateRolesError] = useState(false);
  const [getUserDetail, setGetUserDetail] = useState({
    user_Id: utils.readCookie("UserManage_Id"),
    firstName: utils.readCookie("UserManage_fn"),
    lastName: utils.readCookie("UserManage_ln"),
    userEmail: utils.readCookie("UserManage_email"),
    userName: utils.readCookie("UserManage_Uname"),
  });

  // console.log("userRoleIds", userRoleIds);
  // console.log("getRolesSuccess", getRolesSuccess);

  const handleChange = (e, id, i) => {
    // User Details handling
    setUserToManage({
      ...userToManage,
      [e.target.name]: e.target.value,
    });
    // End

    // User roles/permissions handling
    let rolesArr = userRoleIds;
    let index = rolesArr.indexOf(id);
    if (index !== -1) {
      rolesArr.splice(index, 1);
      setUserRoleIds([...rolesArr]);
      let newPerms = rolesToManage.filter((p, i) => p.id !== id);
      setRolesToManage([...newPerms]);
    } else {
      setUserRoleIds([...userRoleIds, id]);
      setRolesToManage([
        ...rolesToManage,
        { name: e.target.name, id: e.target.id },
      ]);
    }
    // End
  };

  // Api define updateUserPermissionsByAdmin
  const updateUserPermissionsByAdmin = (e) => {
    e.preventDefault();
    setUpdateRolesSuccess({});
    setUpdateRolesError(false);
    // sinner call
    setLoader(true);
    $("#spinnerWrap").css("display", "flex");
    $("#ID_NINJA_SPINNER_IN_PROGRESS").show();
    let url = utils.getServicesUrl() + "/updateUserPermissionsByAdmin";
    let token = utils.readCookie("token");

    var myHeaders = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    var data = JSON.stringify({
      firstName: getUserDetail.firstName,
      userId: getUserDetail.user_Id,
      lastName: getUserDetail.lastName,
      roles: [...rolesToManage],
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: data,
      redirect: "follow",
    };
    var statusCode;
    fetch(url, requestOptions)
      .then((response) => {
        statusCode = response.status;
        return response.text();
      })
      .then((result) => {
        if (statusCode === 403) {
          setUpdateRolesSuccess({
            ...updateRolesSuccess,
            msg403: "You don't have access to this feature.",
          });
          setUpdateRolesError(true);
        } else {
          setUpdateRolesSuccess({
            ...result,
            success: "Your role / permission has been updated.",
          });
          setUpdateRolesError(false);
        }

        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        $("#spinnerWrap").css("display", "none");
        setLoader(false);
      })
      .catch((error) => {
        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        $("#spinnerWrap").css("display", "none");
        setUpdateRolesError(true);
        setUpdateRolesSuccess({
          ...error,
          error:
            "Your role or permission has not been updated due to some technical issues.",
        });
        setLoader(false);
      });
  };
  // End

  // getUserById API Call
  function getUserById() {
    setUpdateRolesSuccess({});
    setUpdateRolesError(false);
    setLoader(true);
    $("#spinnerWrap").css("display", "flex");
    $("#ID_NINJA_SPINNER_IN_PROGRESS").show();
    let url = utils.getServicesUrl() + "/getUserById";
    let token = utils.readCookie("token");
    let UserManage_Id = utils.readCookie("UserManage_Id");

    var myHeaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    var data = JSON.stringify({
      userId: UserManage_Id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: data,
      redirect: "follow",
    };

    var statusCode;
    fetch(url, requestOptions)
      .then((response) => {
        statusCode = response.status;
        return response.json();
      })
      .then((result) => {
        if (statusCode === 403) {
          setUpdateRolesSuccess({
            ...updateRolesSuccess,
            msg403: "You don't have access to this feature.",
          });
          setUpdateRolesError(true);
        } else {
          setGetUserInfo(result);
          let userRoles = result.userInfo.roles;
          setRolesToManage(userRoles);
          let userRoleIds = [];
          userRoles.map((obj) => {
            userRoleIds.push(obj.id);
          });
          setUserRoleIds(userRoleIds);
        }
        // console.log("setGetUserInf", result);
        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        $("#spinnerWrap").css("display", "none");
        setLoader(false);
      })
      .catch((error) => {
        // console.log('error', error)
        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        $("#spinnerWrap").css("display", "none");
        setLoader(false);
        // alert(error);
      });
  }
  // End

  // Api define getAllPermissions/roles
  const getAllPermissions = () => {
    setUpdateRolesSuccess({});
    setUpdateRolesError(false);
    // sinner call
    setLoader(true);
    $("#spinnerWrap").css("display", "flex");
    $("#ID_NINJA_SPINNER_IN_PROGRESS").show();
    let url = utils.getServicesUrl() + "/getAllPermissions";
    let token = utils.readCookie("token");

    var myHeaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    var statusCode;
    fetch(url, requestOptions)
      .then((response) => {
        statusCode = response.status;
        return response.json();
      })
      .then((result) => {
        if (statusCode === 403) {
          setUpdateRolesSuccess({
            ...updateRolesSuccess,
            msg403: "You don't have access to this feature.",
          });
          setUpdateRolesError(true);
        } else {
          setGetRolesSuccess(result);
          $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
          $("#spinnerWrap").css("display", "none");
          setLoader(false);
        }
      })
      .catch((error) => {
        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        $("#spinnerWrap").css("display", "none");
        setLoader(false);
      });
  };
  // End

  // Api define upDate User
  function upDateUser(e) {
    setUpdateUserSuccess({});
    setUpdateUserError(false);
    e.preventDefault();
    // sinner call
    setLoader(true);
    $("#spinnerWrap").css("display", "flex");
    $("#ID_NINJA_SPINNER_IN_PROGRESS").show();
    let url = utils.getServicesUrl() + "/upDateUser";
    let token = utils.readCookie("token");

    var myHeaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    var data = JSON.stringify({
      firstName: userToManage.firstName
        ? userToManage.firstName
        : getUserDetail.firstName,
      lastName: userToManage.lastName
        ? userToManage.lastName
        : getUserDetail.lastName,
      user_id: getUserDetail.user_Id,
      email: userToManage.email ? userToManage.email : getUserDetail.userEmail,
      userName: userToManage.userName
        ? userToManage.userName
        : getUserDetail.userName,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: data,
      redirect: "follow",
    };
    var statusCode;
    fetch(url, requestOptions)
      .then((response) => {
        // console.log("response.json()", response.status);
        statusCode = response.status;
        return response.json();
      })
      .then((result) => {
        if (statusCode === 403) {
          setUpdateUserSuccess({
            msg403: "You don't have access to this feature.",
          });
          setUpdateUserError(true);
        } else if (statusCode === 400) {
          setUpdateUserSuccess({ ...result });
          setUpdateUserError(true);
        } else {
          setUpdateUserSuccess({ ...result });
          setUpdateUserError(false);
        }

        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        $("#spinnerWrap").css("display", "none");
        setLoader(false);
      })
      .catch((error) => {
        // console.log("error", error);
        $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
        $("#spinnerWrap").css("display", "none");
        setUpdateUserError(error);
        setLoader(false);
        // alert(error);
      });
  }
  // End

  // Handle permissions/roles to render
  const renderRole = (role, i) => {
    let status = false;
    if (userRoleIds.indexOf(role.id) !== -1) {
      status = true;
    }
    return (
      <div className="col-4 col-form-label " key={i}>
        <div className="checkbox-inline">
          <label
            className="checkbox checkbox-success"
            style={{ overflowWrap: "anywhere" }}
          >
            <input
              type="checkbox"
              name={role.name}
              id={role.id}
              onChange={(e) => handleChange(e, role.id, i)}
              checked={status}
            />
            <span></span>
            {role.name}
          </label>
        </div>
      </div>
    );
  };
  // End

  const redirectUrl = () => {
    window.location.href = "/admin-dashboard";
  };

  // useEffect hook
  useEffect(() => {
    getUserById();
    getAllPermissions();
    window.addEventListener("load", redirectUrl, false);
  }, []);

  return (
    <div>
      <div id="spinnerWrap">
        <NinjaProgressSpinner maxWidth="100px" maxHeight="100px" />
      </div>
      <section>
        <div className={`card card-custom ${loader ? "isLoaderGen" : ""}`}>
          <div className="card-header">
            <h3 className="card-title">Update user Info:</h3>
          </div>
          <form className="form" onSubmit={(e) => upDateUser(e)}>
            <div className="card-body">
              <div className="form-group row">
                <div className="col-12 col-sm-6">
                  <label>First Name</label>
                  <input
                    type="text"
                    onChange={(e) => handleChange(e)}
                    className="form-control form-control-sm"
                    placeholder="firstName"
                    defaultValue={
                      getUserDetail.firstName && getUserDetail.firstName
                    }
                    name="firstName"
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <label>Last Name</label>
                  <input
                    type="text"
                    onChange={(e) => handleChange(e)}
                    className="form-control form-control-sm"
                    placeholder="lastName"
                    defaultValue={
                      getUserDetail.lastName && getUserDetail.lastName
                    }
                    name="lastName"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-6">
                  <label>User Name:</label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="userName"
                    defaultValue={
                      getUserDetail.userName && getUserDetail.userName
                    }
                    onChange={(e) => handleChange(e)}
                    name="userName"
                  />
                  <div>
                    <span
                      className={
                        updateUserError ? "text-danger" : "text-success"
                      }
                    >
                      {updateUserError && updateUsersuccess.userName}
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <label>Email</label>
                  <input
                    type="email"
                    onChange={(e) => handleChange(e)}
                    className="form-control form-control-sm"
                    placeholder="email"
                    defaultValue={
                      getUserDetail.userEmail && getUserDetail.userEmail
                    }
                    name="email"
                  />
                  <div>
                    <span
                      className={
                        updateUserError ? "text-danger" : "text-success"
                      }
                    >
                      {updateUserError && updateUsersuccess.email}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="text-center">
                <span
                  className={updateUserError ? "text-danger" : "text-success"}
                >
                  {updateUserError
                    ? updateUsersuccess.msg403
                    : updateUsersuccess.message}
                </span>
              </div>
              <button type="submit" class="btn commonButtonClass mr-2">
                Update info
              </button>
            </div>
          </form>
        </div>
      </section>

      <section style={{ marginTop: "25px" }}>
        <div className={`card card-custom ${loader ? "isLoaderGen" : ""}`}>
          <div className="card-header">
            <h3 className="card-title">User Permissions:</h3>
          </div>
          <form
            className="form"
            onSubmit={(e) => updateUserPermissionsByAdmin(e)}
          >
            <div className="card-body">
              {getRolesSuccess ? (
                <div className="form-group row">
                  {getRolesSuccess &&
                    getRolesSuccess.map((v, i) => renderRole(v, i))}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="card-footer">
              <div className="text-center">
                <span
                  className={` ${
                    updateRolesError ? "text-danger" : "text-success"
                  }`}
                >
                  {updateRolesError
                    ? updateRolesSuccess.msg403 && updateRolesSuccess.msg403
                    : updateRolesSuccess.success && updateRolesSuccess.success}
                </span>
                <span
                  className={` ${
                    updateRolesError ? "text-danger" : "text-success"
                  }`}
                >
                  {updateRolesSuccess.error && updateRolesSuccess.error}
                </span>
              </div>
              <button type="submit" className="btn commonButtonClass mr-2">
                Update Permissions
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}
