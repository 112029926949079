import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import $ from "jquery";
import * as Constants from "../../Constants";

import { Panel } from "primereact/components/panel/Panel";
import { Button } from "primereact/components/button/Button";
import { RadioButton } from "primereact/components/radiobutton/RadioButton";
import { DataGrid } from "primereact/components/datagrid/DataGrid";
import NinjaProgressSpinner from "../../components/shared/NinjaProgressSpinner";
import Utils from "../../components/shared/Utils";
import styles from "../../../src/components/RateQuote.module.scss";
import { PasswordTextfield } from "../../shared/inputFields";
import { bindActionCreators } from "redux";
import { createAccountActions } from "../../state/ducks/createAccount/createAccountActions";
import { userActions } from "../../state/ducks/user/userAction";
import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import jstz from "jstz";
const utils = new Utils();
const Styles = (theme) => ({
  root: {
    color: "#E44249",
    "&$checked": {
      color: "#E44249",
    },
  },
  checked: {},
  label: {
    fontFamily: "'ProximaNova-Regular', 'Helvetia', 'Arial','sans-serif'",
    fontSize: "16px",
  },
  formRoot: {
    display: "flex",
    flexDirection: "column",
    width: "35%",
  },
});
class UpdateSubscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: this.props.ninjaRaterApp.state.ninjaStore.userInfo.firstName,
      lastName: this.props.ninjaRaterApp.state.ninjaStore.userInfo.lastName,
      planData: {},
      planDataInfo: {},
      currentPlan: "",
      userPlan: this.props.ninjaRaterApp.state.ninjaStore.userInfo.priceId,
      selectedValue: "",
      spinnerHeight: true,
      show: false,
    };
    this.updateSubscriptionSuccess = this.updateSubscriptionSuccess.bind(this);
    this.updateSubscriptionError = this.updateSubscriptionError.bind(this);
  }

  InProgress = (InProgress) => {
    if (InProgress) {
      this.setState({ InProgress: true });
      this.refs.NinjaProgressSpinner.setState({ display: "block" });
    } else {
      this.setState({ InProgress: false });
      this.refs.NinjaProgressSpinner.setState({ display: "none" });
    }
  };
  componentDidMount() {
    const { actions } = this.props;
    // console.log('this.props', actions)
    $("#ID_NINJA_SPINNER_IN_PROGRESS").show();
    actions.createAccount.getSubscriptionPlans({});
  }

  componentWillReceiveProps(nextProps) {
    $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
    $("#NinjaProgressSpinner").hide();
    let originalData = JSON.parse(JSON.stringify(nextProps.subscriptionPlans));
    let currentPlan = nextProps.subscriptionPlans.filter((data) => {
      return data.priceId == this.state.userPlan;
    });

    try {
      let selectedValue = this.setSelectedValue(currentPlan, originalData);
      this.setState({
        planData: originalData.filter((data) => {
          return data.productPrice != null;
        }),
        currentPlan: currentPlan,
        originalData: originalData,
        selectedValue: selectedValue[0].productId,
      });
    } catch (err) {
      // console.log('err.message', err.message)
      // $( "#displayError" ).append( `<p>${err.message}</p>` );
      document.getElementById("displayError").style.display = "block";
    }
  }
  setSelectedValue = (currentPlan, originalData) => {
    // console.log('currentPlan', currentPlan)
    let selectedValue;
    try {
      if (
        currentPlan[0].priceId.toString() ==
        process.env.REACT_APP_SPECIAL_PRICE_ID
      ) {
        // console.log("REACT_APP_SPECIAL_PRICE_ID",process.env.REACT_APP_SPECIAL_PRICE_ID);
        //if user current plan is free one select monthly plan
        selectedValue = originalData.filter((data) => {
          return data.priceId == process.env.REACT_APP_MONTHLY_PRICE_ID;
        });
      } else if (
        currentPlan[0].priceId.toString() ==
        process.env.REACT_APP_MONTHLY_PRICE_ID
      ) {
        // console.log("REACT_APP_MONTHLY_PRICE_ID",process.env.REACT_APP_MONTHLY_PRICE_ID);
        //if user current plan is monthly one select annual plan
        selectedValue = originalData.filter((data) => {
          return data.priceId == process.env.REACT_APP_ANNUALLY_PRICE_ID;
        });
      } else {
        selectedValue = originalData.filter((data) => {
          return data.priceId == process.env.REACT_APP_MONTHLY_PRICE_ID;
        });
      }
    } catch (err) {
      // console.log('err.message', err.message)
      // $( "#displayError" ).append( `<p>${err.message}</p>` );
    }

    return selectedValue;
  };
  handleChange = (event) => {
    let product = this.state.originalData.filter((item) => {
      if (item.productId == event.target.value) {
        return item.productId == event.target.value;
      }
    });
    this.setState({
      selectedValue: product[0].productId,
    });
  };

  updateSubscription(e) {
    // this.InProgress(true);
    this.setState({ spinnerHeight: false });
    $("#ID_NINJA_SPINNER_IN_PROGRESS").show();
    $("#NinjaProgressSpinner").show();
    const { originalData, selectedValue } = this.state;
    let plan = originalData.filter((data) => {
      return data.productId == selectedValue;
    });

    let planType;
    if (Constants.IS_PRODUCTION) {
      plan[0].productName == "Monthly"
        ? (planType = "MONTHLY")
        : (planType = "ANNUALLY");
    } else {
      plan[0].productName == "Monthly"
        ? (planType = "MONTHLY_TEST")
        : (planType = "ANNUALLY_TEST");
    }

    // console.log("planDataInfo",this.state.planDataInfo);

    let timeZone = jstz.determine().name();
    let url =
      utils.getServicesUrl() +
      "/updateSubscriptionPlan?priceId=" +
      `${this.state.planDataInfo.priceId}` +
      "&planName=" +
      `${this.state.planDataInfo.planName}`;
    url +=
      "&tmz=" +
      timeZone +
      "&live=" +
      Constants.IS_PRODUCTION +
      "&numberDays=" +
      `${this.state.planDataInfo.numberDays}`;
    utils.ajaxRequest(
      "PUT",
      url,
      this.updateSubscriptionSuccess,
      this.updateSubscriptionError,
      {},
      true
    );
    // for confirmation modal
    const handleClose = () => this.setState({ show: false });
    // Modal close
    handleClose();
    //
  }
  updateSubscriptionSuccess(data, status, response) {
    // this.InProgress(false);
    let newData = JSON.parse(data);
    // console.log("data",data)
    $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
    $("#NinjaProgressSpinner").hide();
    let updatedValue = this.state.originalData.filter((item) => {
      return item.priceId == newData.userInfo.priceId;
    });
    let selectedValue = this.setSelectedValue(
      updatedValue,
      this.state.originalData
    );
    this.setState((state) => {
      this.state.updateSubscriptionChangedMessage =
        "Success! Your Subscription is Updated";
      this.state.pageErrorMessage = null;
      this.state.selectedValue = selectedValue[0].productId;
      this.state.currentPlan = updatedValue;
      this.state.userPlan = updatedValue[0].priceId;
      return state;
    });
    this.setState({ spinnerHeight: true });
    setTimeout(() => {
      this.setState({ updateSubscriptionChangedMessage: null });
    }, 15000);
  }

  updateSubscriptionError(jqXHR, exception) {
    // this.InProgress(false);
    // let errorResponse = utils.parseResponseError(jqXHR, exception);
    // this.setState({pageErrorMessage: errorResponse});
    $("#ID_NINJA_SPINNER_IN_PROGRESS").hide();
    $("#NinjaProgressSpinner").hide();
    try {
      this.setState({ pageErrorMessage: jqXHR.responseJSON.message });
      this.setState({ updateSubscriptionChangedMessage: null });
      setTimeout(() => {
        this.setState({ pageErrorMessage: null });
        // window.location.reload();
      }, 15000);
    } catch (err) {
      // console.log(err.message)
    }
  }

  render() {
    const {
      firstName,
      lastName,
      planData,
      planDataInfo,
      currentPlan,
      userPlan,
      selectedValue,
      spinnerHeight,
    } = this.state;
    var { classes } = this.props;
    // for confirmation modal
    const handleClose = () => this.setState({ show: false });
    const handleShow = () => this.setState({ show: true });
    //
    console.log("this.state", this.state);
    return (
      <React.Fragment>
        <div className="ui-g-12 no-padding" style={{ width: "100%" }}>
          <p className={["previous_head", "commonButtonClass"].join(" ")}>
            Update Subscription
          </p>
        </div>
        <div
          id="displayError"
          style={{
            width: "100%",
            textAlign: "center",
            padding: "10px",
            marginBottom: "16em",
            display: "none",
          }}
        >
          <p>Sorry... We are experiencing technical difficulties</p>
          <p>Please try again later. Or call us at 1 (866) 764-6610.</p>
        </div>
        <div
          id="NinjaProgressSpinner"
          style={{ textAlign: "center" }}
          style={{ height: this.state.spinnerHeight ? "500px" : "0px" }}
          className="container"
        >
          <NinjaProgressSpinner
            ref="NinjaProgressSpinner"
            maxWidth="200px"
            maxHeight="200px"
            marginTop="-10px"
          />
        </div>
        {planData && currentPlan && (
          <div className="ui-g" style={{ width: "100%" }}>
            <div
              className="ui-g"
              style={{ fontSize: "1.2em", display: "contents" }}
            >
              <div
                id="updateSubscription"
                className="ui-g-12 no-padding"
                style={{ width: "50%", margin: "auto", marginTop: "15px" }}
              >
                <div
                  className="ui-g-12 commonButtonClass"
                  style={{ textAlign: "center", width: "100%" }}
                >
                  <span> Current Subscription Plan </span>
                </div>
                <Panel>
                  <div className="container">
                    <div className="overview-content clearfix">
                      <div className="justify-content-md-center">
                        <div className="card-deck mb-3 text-center col-md-auto">
                          <div className="card mb-4 box-shadow no-padding">
                            <div className="card-header">
                              <h4 className="my-0 font-weight-normal">
                                {currentPlan[0].productName}
                              </h4>
                            </div>
                            {currentPlan[0].productPrice > 0 && (
                              <div className="card-body">
                                {currentPlan[0].productName ==
                                  "First two months FREE!" && (
                                  <h1 className="card-title pricing-card-title">
                                    ${currentPlan[0].productPrice} /{" "}
                                    <span>
                                      <span className={styles.durationFont}>
                                        month
                                      </span>
                                    </span>
                                  </h1>
                                )}
                                {currentPlan[0].productName == "Monthly" && (
                                  <h1 className="card-title pricing-card-title">
                                    ${currentPlan[0].productPrice} /{" "}
                                    <span>
                                      <span className={styles.durationFont}>
                                        month
                                      </span>
                                    </span>
                                  </h1>
                                )}
                                {currentPlan[0].productName == "Annually" && (
                                  <h1 className="card-title pricing-card-title">
                                    ${currentPlan[0].productPrice} /{" "}
                                    <span>
                                      {currentPlan[0].productDuration ==
                                      "1 month" ? (
                                        <span className={styles.durationFont}>
                                          month
                                        </span>
                                      ) : (
                                        <React.Fragment>
                                          <span className={styles.durationFont}>
                                            annual{" "}
                                          </span>
                                          <span className={styles.durationFont}>
                                            1 month free!
                                          </span>
                                        </React.Fragment>
                                      )}
                                    </span>
                                  </h1>
                                )}
                              </div>
                            )}
                            <div
                              className={
                                currentPlan[0].productPrice
                                  ? "card-footer"
                                  : "card-body"
                              }
                            >
                              <small>{currentPlan[0].productDiscraption}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="ui-g-12"
                      style={{ textAlign: "center", marginTop: "20px" }}
                    >
                      <span className="text" style={{ color: "#ec4b3c" }}>
                        To upgrade, Choose plan below
                      </span>
                    </div>
                    <div className="ui-g-12" style={{ textAlign: "center" }}>
                      <div className="row justify-content-md-center">
                        <RadioGroup
                          onChange={this.handleChange.bind(this)}
                          // value={selectedValue.toString()}
                        >
                          {planData.map((value, i) => (
                            <FormControlLabel
                              key={i}
                              value={value.productId}
                              control={
                                <Radio
                                  color="default"
                                  onClick={() => {
                                    this.setState({
                                      planDataInfo: {
                                        priceId: value.priceId,
                                        planName: value.planName,
                                        numberDays:
                                          value.productName == "Annually"
                                            ? 365
                                            : 30,
                                      },
                                    });
                                  }}
                                  className={classes.root}
                                  disabled={value.priceId == userPlan}
                                />
                              }
                              label={
                                <React.Fragment>
                                  {value.productName ==
                                    "First two months FREE!" && (
                                    <span className={styles.priceSize}>
                                      First two months FREE!
                                    </span>
                                  )}
                                  {value.productName == "Monthly" && (
                                    <span className={styles.priceSize}>
                                      ${value.productPrice} /{" "}
                                      <span className={styles.durationFont}>
                                        month
                                      </span>
                                    </span>
                                  )}

                                  {value.productName == "Annually" && (
                                    <span className={styles.priceSize}>
                                      ${value.productPrice} /{" "}
                                      <span className={styles.durationFont}>
                                        annual
                                      </span>{" "}
                                      <span className={styles.durationFont}>
                                        1 month free!
                                      </span>
                                    </span>
                                  )}
                                </React.Fragment>
                              }
                            />
                          ))}
                        </RadioGroup>
                      </div>
                    </div>
                  </div>

                  <div
                    className="ui-g-12 no-padding"
                    id="ID_PSWD_ERROR"
                    style={{
                      display: this.state.pageErrorMessage ? "block" : "none",
                    }}
                  >
                    <div
                      style={{
                        color: "#b00020",
                        // fontWeight: "bold",
                        fontSize: "16px",
                        marginLeft: "15px",
                        textAlign: "center",
                      }}
                    >
                      *{this.state.pageErrorMessage}
                      <br />
                    </div>
                  </div>

                  <div
                    className="ui-g-12 no-padding"
                    style={{
                      display: this.state.updateSubscriptionChangedMessage
                        ? "block"
                        : "none",
                    }}
                  >
                    <div
                      style={{
                        color: "green",
                        fontWeight: "bold",
                        fontSize: "16px",
                        marginLeft: "15px",
                        textAlign: "center",
                      }}
                    >
                      {this.state.updateSubscriptionChangedMessage}
                      <br />
                    </div>
                  </div>

                  <div className="ui-g-12" style={{ textAlign: "center" }}>
                    <Button
                      //className="change_password_button commonButtonClass"
                      className="commonButtonClass"
                      id="ID_UPDATE_SUBSCRIPTION"
                      type="button"
                      label="Update Subscription"
                      onClick={handleShow}
                      style={{ fontWeight: "600" }}
                      disabled={planDataInfo.priceId ? false : true}
                    ></Button>

                    <Modal
                      show={this.state.show}
                      onHide={handleClose}
                      backdrop="static"
                      keyboard={false}
                    >
                      <Modal.Body>
                        Are you sure! you want to update subscription?
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          style={{ background: "#e3160b", fontWeight: "600" }}
                          onClick={handleClose}
                        >
                          Close
                        </Button>
                        <Button
                          variant="primary"
                          className="commonButtonClass"
                          onClick={(e) => this.updateSubscription(e)}
                        >
                          Confirm
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </Panel>
              </div>
            </div>
            <br />
          </div>
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    subscriptionPlans: state.createAccountAjaxCallsReducer.subscriptionPlans,
  };
};
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      createAccount: bindActionCreators(createAccountActions, dispatch),
      userActions: bindActionCreators(userActions, dispatch),
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(Styles)(UpdateSubscription));
